import React from "react";

import Header from '../../header';
import Footer from '../../footer';
import CampsForm from '../../components/forms/admin/CampsForm';

export default class AddCampsPage extends React.Component {

  render() {
    return (
      <div>
           <Header />
           <div className="view textArticle camps">
              <h1>Přidání nového kempu</h1>

              <CampsForm />
           </div>

            <Footer />
      </div>
    )
  }

}
