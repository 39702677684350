import React from "react";

import Header from '../../header';
import Footer from '../../footer';
import ApplicationFormAutumn from '../../components/forms/ApplicationFormAutumn';
import PhotoGallery from '../../components/PhotoGallery';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import {getCampIntroduction, loadCampsDataAndRender, loadPhotoGalleryImages, getPhotosFromGallery} from './CampsUtil';


const eventName = "Brno-Bohunice";

export default class AutumnCamps extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("autumn_camps", this);
      loadPhotoGalleryImages("goalkeepers_day_camps", eventName, this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;
    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);
    }

    let loading = isLoading || isIntroLoading || isPhotoGalleryLoading

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
            {hideHeaderAndFooter ? '' : <Header />}

            <div class="view textArticle camps">
                <div className="left">
                  {getCampIntroduction(campIntroduction, photoUrl)}
                </div>

              

                <PhotoGallery photos={getPhotosFromGallery(eventName, photoGallery)}/>


            </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>
    );
  }

}
