import React from "react";

import Header from '../../header';
import Footer from '../../footer';
import ApplicationFormAutumn from '../../components/forms/ApplicationFormAutumn';
import PhotoGallery from '../../components/PhotoGallery';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import Photo from '../../images/events/april_2024.png';


export default class AprilEvent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return(
      <div>
            <Header />

            <div class="view textArticle camps">
                <div className="left">
                  <div>
                    <img src={Photo} alt="Májové mini šílenství"/>
                  </div>

                  <p>
                    V brankářské životě jsme zjistili, že toho <b>šílení a chytání prostě není nikdy dost.</b> Tenhle pohár je snad bezednej..:D A proto přidáváme akci, při které do toho poháru zase přilejeme další dávku radosti a potrénujeme na mega akci <a href='https://j4k.cz/#/brankarskekempy/den-brankaru'>Den brankářů</a>.
                  </p>
                  <p>
                    Tak si přijď oživit <b>brankářské souboje</b>, jejich pravidla a načerpat rady a zkušenosti, které pak vytasíš v plné parádě.
                  </p>
                  <p>
                    Vidíme se <b>8.5. 2024 na hřišti Moravské Slavie v Brně.</b>
                  </p>
                  <p>
                    Sraz v 9, konec 15:30.
                  </p>
                  <p>
                    <b>Cena: </b>800,-
                  </p>
                  <p>
                    - oběd, pitný režim, svačina, trénink dovedností s profesionálními trenéry a mini turnaj
                  </p>
                  <p>
                    Těšíme se :)
                  </p>
                  <p>
                    <a href='https://just4keepers.dtap.cz/register/?link=11'>Přihláška tady</a>
                  </p>
                </div>
            </div>

            <Footer />
      </div>
    );
  }

}
