import React from 'react';
import firebase from '../../../database/firestore';

// Forms
import {Formik} from 'formik';
import {Form, Button, Col} from 'react-bootstrap';
import "../../../css/bootstrap.scss";
// Form validation
import * as yup from 'yup';

// Notification pop up
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const schema = yup.object({
  start_date: yup.string().required('Pocatecni datum je povinný údaj'),
  end_date: yup.string().required('Konecny datum je povinný údaj')
});


class CampsForm extends React.Component {

  constructor() {
   super();
   this.applicantForm = React.createRef();

   this.state = {
     camp_type: 'summer_camps',
     camp_place: 'Libice nad Doubravou'
   }

 }

 setType = e => {
     this.setState({camp_type: e});
 }

 setPlace = e => {
     this.setState({camp_place: e});
 }

  saveNewCamp(values) {
     console.log("saving", values);
    const db = firebase.firestore();
    db.settings({
      //timestampsInSnapshots: true // without this, we get a warning in the console, which tells us the timestamps stored in Firestore will be read back as timestamp objects, as opposed to system date objects.
    });

    let campsPath = this.state.camp_type;
    let place = this.state.camp_place;
    let campDocument = '/' + place + '/' + values.start_date + ' - ' + values.end_date + '/' + 'detail';

    db.collection(campsPath)
      .doc(campDocument)
      .set({
        start_date: values.start_date,
        end_date: values.end_date,
        price: values.price + ' Kč',
        priceNotification: values.price + ' Kč',
        uid: values.uid})
      .then(() => {
        console.log("Sucessfull insert into camps path");
        let notificationOptions = {
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        };

        toast.success("Kemp úspěšně uložen.", notificationOptions);
       }).catch(error => {
         let errorNotificationOptions = {
           autoClose: false,
           type: toast.TYPE.ERROR
         };
         toast.error("Uložení kampu se nezdařilo, zkuste to prosím později.", errorNotificationOptions);
        console.log(error);
    });
  };

  render() {

    return (
      <div id='applicationForm' className="use-bootstrap">
         <h4>Termin</h4>
        <Formik
             validationSchema={schema}
             onSubmit={ values => this.saveNewCamp(values) }
             initialValues={{
               start_date: '10.7.',
               end_date: '13.7. 2023',
               price: '4500'
             }}
           >
             {({
               handleSubmit,
               handleChange,
               handleBlur,
               values,
               touched,
               isValid,
               errors
             }) => (
               <Form noValidate onSubmit={handleSubmit}
                                ref={this.applicantForm}>

                <Form.Row>
                                <Form.Group controlId="formBasicSelect">
                                 <Form.Label>Vyber typ kempu:</Form.Label>
                                 <Form.Control
                                   as="select"
                                   value={values.camp_type}
                                   onChange={e => {
                                     console.log("Selected camp type:", e.target.value);
                                     this.setType(e.target.value);
                                   }}
                                 >
                                   <option value="summer_camps">Letni kempy</option>
                                   <option value="autumn_camps">Podzimni kempy</option>
                                   <option value="winter_camps">Zimni kempy</option>
                                   <option value="silvester_camps">Silvester</option>
                                 </Form.Control>
                               </Form.Group>

                               <Form.Group controlId="formBasicSelectCampPlace">
                                <Form.Label>Vyber lokalitu kempu:</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={values.camp_place}
                                  onChange={e => {
                                    console.log("Selected place: ", e.target.value);
                                    this.setPlace(e.target.value);
                                  }}
                                >
                                  <option value="Libice nad Doubravou">Libice nad Doubravou</option>
                                  <option value="Třešť">Třešť</option>
                                  <option value="Luka nad Jihlavou">Luka nad Jihlavou</option>
                                  <option value="Humpolec">Humpolec</option>
                                  <option value="Silvestrovské chytání">Silvestrovské chytání</option>
                                </Form.Control>
                              </Form.Group>
                </Form.Row>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormikCampStartDate">
                     <Form.Label>Termin od</Form.Label>
                      <Form.Control
                         type="text"
                         placeholder="18.7."
                         name="start_date"
                         value={values.start_date}
                         onChange={handleChange}
                         isInvalid={!!errors.start_date}
                         isValid={errors.start_date}
                      />

                      <Form.Control.Feedback type="invalid">
                         {errors.title}
                      </Form.Control.Feedback>
                   </Form.Group>

                   <Form.Group as={Col} md="4" controlId="validationFormikCampEndData">
                     <Form.Label>Termin do</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="21.7.2022"
                       name="end_date"
                       value={values.end_date}
                       onChange={handleChange}
                       isInvalid={!!errors.end_date}
                     />

                     <Form.Control.Feedback type="invalid">
                        {errors.end_date}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>


                 <Form.Row>
                   <h4 className="left">Cena</h4>
                   <Form.Group as={Col} md="8" controlId="validationFormikPrice">
                     <Form.Label>Cena v Kč</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="4500"
                       name="price"
                       value={values.price}
                       onChange={handleChange}
                       isInvalid={!!errors.price}
                     />

                     <Form.Control.Feedback type="invalid">
                       {errors.price}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>


                <Form.Row>
                  <h4 className="left">ADMIN ID</h4>
                  <Form.Group as={Col} md="8" controlId="validationFormikCampsUID">
                    <Form.Label>admin</Form.Label>
                    <Form.Control
                      type="text"
                      name="uid"
                      value={values.uid}
                      onChange={handleChange}
                      isInvalid={!!errors.uid}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.uid}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Button type="submit">Uložit kemp</Button>
              </Form>
             )}
           </Formik>

           <ToastContainer position="top-center" className="toast-container" />

      </div>
        );
      }
   }
export default CampsForm;
