import React from "react";

import aboutPhoto from '../../../images/about/about.jpg';

import Header from '../../../header';
import Footer from '../../../footer';


export default class Coaches extends React.Component {

  render() {

    return (
      <div>
    		<Header />

        <div className="view gameReport try-training">
            <div
              key="blogs_key"
              className="try-training-image"
              style={{ background: `url('${aboutPhoto}') no-repeat center center` }}
            />
            <div className="headline">
              <h2>
                Připravujeme...
              </h2>
            </div>
          </div>



        <Footer />
      </div>
    );
  }
}
