import React from "react";

import Header from '../../header';
import Footer from '../../footer';
import ApplicationFormWinter from '../../components/forms/ApplicationFormWinter';

import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import {getCampArticles, getCampIntroduction, loadCampsDataAndRender} from './CampsUtil';

export default class AutumnCamps extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true,
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("winter_camps", this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;
    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);
    }

    let loading = isLoading || isIntroLoading || isPhotoGalleryLoading

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
            {hideHeaderAndFooter ? '' : <Header />}

            <div class="view textArticle camps">

              {getCampIntroduction(campIntroduction, photoUrl)}

              <ApplicationFormWinter />

              {getCampArticles(campsData, photoGallery)}

            </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>
    );
  }

}
