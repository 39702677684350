import React from "react";

import {NavLink} from "react-router-dom";

import '../../css/styles.css';

import MainArticleData from '../../components/data/MainArticleData';

export default class NavigationImages extends React.Component {

  render() {
    const data = new MainArticleData();
    const navigations = data.getBottomImageNavigations();

    return (
      <div className="bottom-navigation-images">

          <div className="responsive left bottom-image-navigations-section">
          {
            navigations.map((navigation, index) => (
              <div className="navigation-image-column item">
                <button>
                  <NavLink to= {navigation.target}>
                    <img src={navigation.image} alt={navigation.title} />
                    </NavLink>
                </button>
                <span class="caption">{navigation.title}</span>
              </div>
            ))
           }
          </div>

      </div>
    );
  }

}
