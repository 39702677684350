import React from "react";

import {NavLink} from "react-router-dom";

import MainArticleData from '../../components/data/MainArticleData';

import '../../css/styles.css';


export default class FamilyMemberNavigation extends React.Component {

  render() {
    const data = new MainArticleData();
    const navigations = data.getFamilyMemberNavigations();

    return (
      <div className="family-member-navigations-section">
        <h3>Staň se členem rodiny</h3>

        <p>
          V <b>J4K</b> rodině vnímáme důležitost správného nácviku brankářských dovedností, přátelství, zábavy a plnění vlastních snů. Pokud něco z toho hledáš, jsi náš člověk!
        </p>

        <div className="responsive left">
        {
          navigations.map((navigation, index) => (
            <div className="navigation-image-column item">
              <button>
                <NavLink to= {navigation.target}>
                  <img src={navigation.image} alt={navigation.title} />
                  </NavLink>
              </button>
              <span class="caption">{navigation.title}</span>
            </div>
          ))
         }
        </div>

      </div>
    );
  }

}
