import React from "react";

import { SocialIcon } from 'react-social-icons';

const Footer = () => {
  return (
      <div className="footer">

	  <div className="socialMedia">
        <SocialIcon url="https://www.facebook.com/just4keepersjicha/" />
        <SocialIcon url="https://www.instagram.com/just4keeperscz/" />
        <SocialIcon url="https://www.youtube.com/channel/UCY_avamFmV1fPcdCQxiLuag/videos?view_as=subscriber" />
    </div>

      <div className="footer-copyright">
        Brankářská škola Just4keepers CZ | <a href="mailto:just4keeperscz@gmail.com">just4keeperscz@gmail.com</a> | 734 171 793
      </div>
	  </div>

  );
}

export default Footer;
