import React from "react";

import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import Header from '../../header';
import Footer from '../../footer';

// Views
import TextReport from '../TextReport';
import GoalkeepersDay from '../camps/GoalkeepersDay';

import {loadNewsByTitleAndRender, getWelcomePhoto, getPhotosFromGallery} from './NewsUtil'


export default class ArticleNewsDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      props: props,
      newsData: [],
      photoGallery: [],
      welcomePhotos: [],
      isLoading: true
    };
  }

  componentDidMount(props){
      loadNewsByTitleAndRender(this.state.props.match.params.title, this);
  }

  render() {
    const title = this.props.match.params.title;
    const {newsData, isLoading, welcomePhotos, photoGallery} = this.state;

    let isWelcomePhotoLoading = true;
    let isGalleriesLoading = true;
    let numberOfGalleriesToLoad = 0;
    let data = undefined;
    // dynamic loading of news from database
    if(!isLoading) {
      data = newsData[0];
      numberOfGalleriesToLoad = data.hasGallery ? 1 : 0;

      isGalleriesLoading = photoGallery.length !== numberOfGalleriesToLoad;
      isWelcomePhotoLoading = welcomePhotos.length === 0;
    }

    let loading = isLoading || isWelcomePhotoLoading || isGalleriesLoading;
    console.log("isWelcomePhotoLoading= " + isWelcomePhotoLoading + " isLoading= " + isLoading + ". isGalleriesLoading= " + isGalleriesLoading);

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (

	     <div>
		        <Header />
		          {data.goalKeepersDay !== undefined && data.goalKeepersDay ? <GoalkeepersDay />
              : <TextReport article={data} photo={getWelcomePhoto(title, welcomePhotos)} photoGallery={getPhotosFromGallery(title, photoGallery)} />}
            <Footer />
	     </div>
    );
  }

}
