import React from "react";

export default class PrivacyPolicyAndSecurity extends React.Component {

  render() {

    return (
	     <div class="indent">
         <p>Tyto z&aacute;sady ochrany osobn&iacute;ch &uacute;dajů (d&aacute;le jen &bdquo;<strong>Z&aacute;sady</strong>&ldquo;) popisuj&iacute; způsob z&iacute;sk&aacute;v&aacute;n&iacute;, použ&iacute;v&aacute;n&iacute; a dal&scaron;&iacute;ho nakl&aacute;d&aacute;n&iacute; s&nbsp;osobn&iacute;mi &uacute;daji z&iacute;skan&yacute;mi prostřednictv&iacute;m webov&eacute;ho rozhran&iacute; www.j4k.cz&nbsp;(d&aacute;le jen &bdquo;<strong>webov&eacute; rozhran&iacute;</strong>&ldquo;)</p>

<p><strong>Spr&aacute;vce osobn&iacute;ch &uacute;dajů:</strong></p>

<p>Just4keepers CZ s.r.o</p>

<p>IČ:&nbsp;05854661</p>

<p>DIČ:</p>

<p><strong>se s&iacute;dlem:&nbsp;</strong>Salavice 75, 589 01 Tře&scaron;ť</p>

<p>zapsan&eacute; u Krajsk&eacute;ho soudu v Brně, spisov&aacute; značka C 98552</p>

<p><strong>kontaktn&iacute; &uacute;daje:</strong></p>

<p>email: just4keeperscz @gmail.com</p>

<p>telefon: +420 734 171 793</p>

<p>Kontaktn&iacute; &uacute;daje spr&aacute;vce osobn&iacute;ch &uacute;dajů:</p>

<p>Adresa pro doručov&aacute;n&iacute;: Just4keepers CZ s.r.o., Salavice 75, 58901 Tře&scaron;ť</p>

<p>Telefonn&iacute; č&iacute;slo: +420 734 171 793</p>

<p>Kontaktn&iacute; e-mail: just4keeperscz@gmail.com</p>

<p><strong>&nbsp;</strong></p>

<p><strong>Ochrana osobn&iacute;ch &uacute;dajů je pro n&aacute;s velice důležit&aacute;. Pros&iacute;me o důsledn&eacute; sezn&aacute;men&iacute; se s&nbsp;těmito Z&aacute;sadami, kter&eacute; obsahuj&iacute; důležit&eacute; informace t&yacute;kaj&iacute;c&iacute; se nakl&aacute;d&aacute;n&iacute; s va&scaron;imi osobn&iacute;mi &uacute;daji a souvisej&iacute;c&iacute;ch pr&aacute;v a povinnost&iacute;.</strong></p>

<p><strong>&nbsp;</strong></p>

<ol>
<li><strong>&Uacute;VODN&Iacute; USTANOVEN&Iacute;</strong>

<ul>
  <li><strong>Č&iacute;m se ř&iacute;d&iacute;me při nakl&aacute;d&aacute;n&iacute; s&nbsp;osobn&iacute;mi &uacute;daji?</strong></li>
</ul>
</li>
</ol>

<p>Při nakl&aacute;d&aacute;n&iacute; s&nbsp;osobn&iacute;mi &uacute;daji postupujeme v&nbsp;souladu s&nbsp;pr&aacute;vn&iacute;m ř&aacute;dem Česk&eacute; republiky a př&iacute;mo použiteln&yacute;mi předpisy Evropsk&eacute; unie, zejm&eacute;na se z&aacute;konem č. 101/2000 Sb., o ochraně osobn&iacute;ch &uacute;dajů, ve zněn&iacute; pozděj&scaron;&iacute;ch předpisů (d&aacute;le jen &bdquo;<strong>ZOO&Uacute;</strong>&ldquo;) a z&aacute;konem č.&nbsp;480/2004 Sb., o někter&yacute;ch služb&aacute;ch informačn&iacute; společnosti a o změně někter&yacute;ch z&aacute;konů, ve zněn&iacute; pozděj&scaron;&iacute;ch předpisů.</p>

<ul>
<li><strong>Co jsou osobn&iacute; &uacute;daje?</strong></li>
</ul>

<p>Osobn&iacute;mi &uacute;daji se rozum&iacute; ve&scaron;ker&eacute; informace, kter&eacute; identifikuj&iacute; nebo mohou identifikovat konkr&eacute;tn&iacute; fyzickou osobu. Osobn&iacute;mi &uacute;daji jsou zejm&eacute;na (av&scaron;ak nikoliv v&yacute;lučně):</p>

<ul>
<li><strong>identifikačn&iacute; &uacute;daje</strong>, jako např. jm&eacute;no a př&iacute;jmen&iacute;, rodn&eacute; č&iacute;slo, datum narozen&iacute;, pohlav&iacute;, přihla&scaron;ovac&iacute; jm&eacute;no do uživatelsk&eacute;ho &uacute;čtu;</li>
<li><strong>kontaktn&iacute; &uacute;daje</strong>, jako např. adresa bydli&scaron;tě (nebo doručovac&iacute; adresa), telefonn&iacute; č&iacute;slo, emailov&aacute; adresa;</li>
<li><strong>dal&scaron;&iacute; &uacute;daje</strong>, jako např. informace z&iacute;skan&eacute; pomoc&iacute; souborů cookies, IP adresa (s&iacute;ťov&yacute; identifik&aacute;tor) včetně typu prohl&iacute;žeče, zař&iacute;zen&iacute; a operačn&iacute;ho syst&eacute;mu, doby a počtu př&iacute;stupů na webov&eacute; rozhran&iacute; a dal&scaron;&iacute; obdobn&eacute; informace.</li>
</ul>

<p>&nbsp;</p>

<ol>
<li><strong>Z&Iacute;SK&Aacute;V&Aacute;N&Iacute; A VYUŽ&Iacute;V&Aacute;N&Iacute; OSOBN&Iacute;CH &Uacute;DAJŮ</strong>

<ul>
  <li><strong>Jak z&iacute;sk&aacute;v&aacute;me va&scaron;e osobn&iacute; &uacute;daje?</strong></li>
</ul>
</li>
</ol>

<p>Sv&eacute; osobn&iacute; &uacute;daje n&aacute;m poskytujete zejm&eacute;na při vyplněn&iacute; objedn&aacute;vky, př&iacute;padně při zř&iacute;zen&iacute; uživatelsk&eacute;ho &uacute;čtu.</p>

<p>Při n&aacute;v&scaron;těvě a už&iacute;v&aacute;n&iacute; webov&eacute;ho rozhran&iacute; může d&aacute;le doch&aacute;zet k&nbsp;z&iacute;sk&aacute;v&aacute;n&iacute; a ukl&aacute;d&aacute;n&iacute; někter&yacute;ch osobn&iacute;ch &uacute;dajů prostřednictv&iacute;m souborů cookies. V&iacute;ce o souborech cookies se dočtete v&nbsp;čl&aacute;nku 5 těchto Z&aacute;sad.</p>

<ul>
<li><strong>Na jak&eacute;m z&aacute;kladě a pro jak&eacute; &uacute;čely zpracov&aacute;v&aacute;me va&scaron;e osobn&iacute; &uacute;daje?</strong></li>
</ul>

<ul>
<li>Osobn&iacute; &uacute;daje zadan&eacute;&nbsp;<strong>při objedn&aacute;v&aacute;n&iacute; zbož&iacute;&nbsp;</strong>můžeme bez va&scaron;eho v&yacute;slovn&eacute;ho souhlasu shromažďovat a zpracov&aacute;vat v&yacute;lučně za &uacute;čelem&nbsp;<strong>plněn&iacute; smlouvy</strong>, tedy za &uacute;čelem dod&aacute;n&iacute; zbož&iacute;. D&aacute;le můžeme tyto &uacute;daje zpracov&aacute;vat za &uacute;čelem&nbsp;<strong>splněn&iacute; na&scaron;ich dal&scaron;&iacute;ch z&aacute;konem stanoven&yacute;ch povinnost&iacute;&nbsp;</strong>(zejm&eacute;na evidenčn&iacute; povinnosti, archivace daňov&yacute;ch dokladů apod.).</li>
<li>Osobn&iacute; &uacute;daje zadan&eacute;&nbsp;<strong>při založen&iacute; uživatelsk&eacute;ho &uacute;čtu</strong>&nbsp;můžeme bez va&scaron;eho v&yacute;slovn&eacute;ho souhlasu shromažďovat a zpracov&aacute;vat v&yacute;lučně za &uacute;čelem umožněn&iacute; př&iacute;stupu, spr&aacute;vy a veden&iacute; uživatelsk&eacute;ho &uacute;čtu.</li>
<li>Va&scaron;i e-mailovou adresu jsme opr&aacute;vněni bez va&scaron;eho v&yacute;slovn&eacute;ho souhlasu využ&iacute;t pro zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute; t&yacute;kaj&iacute;c&iacute;ch se na&scaron;eho zbož&iacute; obdobn&eacute;ho tomu, kter&eacute; jste si od n&aacute;s objednali. Zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute; můžete kdykoliv odm&iacute;tnout.</li>
<li>Pokud n&aacute;m k&nbsp;tomu potvrzen&iacute;m na webov&eacute;m rozhran&iacute; d&aacute;te&nbsp;<strong>souhlas</strong>, můžeme va&scaron;e osobn&iacute; &uacute;daje zadan&eacute; při vyplněn&iacute; objedn&aacute;vky nebo při založen&iacute; uživatelsk&eacute;ho &uacute;čtu zpracov&aacute;vat za &uacute;čelem zas&iacute;l&aacute;n&iacute;&nbsp;<strong>obchodn&iacute;ch sdělen&iacute;</strong>&nbsp;a&nbsp;<strong>př&iacute;m&eacute;ho marketingu</strong>, př&iacute;padně za dal&scaron;&iacute;mi &uacute;čely, se kter&yacute;mi jste v&yacute;slovně souhlasili.</li>
<li>Osobn&iacute; &uacute;daje z&iacute;sk&aacute;van&eacute;&nbsp;<strong>prostřednictv&iacute;m souborů cookies</strong>&nbsp;zpracov&aacute;v&aacute;me na z&aacute;kladě va&scaron;eho souhlasu (kter&yacute; můžete udělit potvrzen&iacute;m na webov&eacute;m rozhran&iacute; pot&eacute;, co jste byli na použ&iacute;v&aacute;n&iacute; cookies upozorněni a měli jste možnost sezn&aacute;mit se s&nbsp;těmito Z&aacute;sadami). Osobn&iacute; &uacute;daje z&iacute;sk&aacute;van&eacute; prostřednictv&iacute;m souborů cookies jsme opr&aacute;vněni zpracov&aacute;vat i v&nbsp;př&iacute;padě, že d&aacute;le už&iacute;v&aacute;te webov&eacute; rozhran&iacute; i pot&eacute;, co jste byli na zpracov&aacute;n&iacute; těchto osobn&iacute;ch &uacute;dajů upozorněni. Osobn&iacute; &uacute;daje z&iacute;sk&aacute;van&eacute; prostřednictv&iacute;m souborů cookies využ&iacute;v&aacute;me pro &uacute;čely&nbsp;<strong>prov&aacute;děn&iacute; uživatelsk&eacute; podpory</strong>,<strong>&nbsp;zlep&scaron;ov&aacute;n&iacute; na&scaron;ich služeb včetně anal&yacute;zy chov&aacute;n&iacute; uživatelů&nbsp;</strong>a<strong>&nbsp;marketingu</strong>.</li>
</ul>

<p><strong>Použ&iacute;t va&scaron;e osobn&iacute; &uacute;daje k&nbsp;jin&eacute;mu &uacute;čelu, než pro kter&yacute; byly z&iacute;sk&aacute;ny, můžeme jen na z&aacute;kladě va&scaron;eho souhlasu.</strong></p>

<ul>
<li><strong>Po jak dlouhou dobu &uacute;daje využ&iacute;v&aacute;me?</strong></li>
</ul>

<p>Osobn&iacute; &uacute;daje zadan&eacute; v&nbsp;r&aacute;mci objedn&aacute;v&aacute;n&iacute; zbož&iacute; nebo v&nbsp;r&aacute;mci registrace využ&iacute;v&aacute;me pouze po dobu nezbytnou pro splněn&iacute; smlouvy a splněn&iacute; pr&aacute;vn&iacute;ch povinnost&iacute;.</p>

<p>Pokud n&aacute;m uděl&iacute;te v&yacute;slovn&yacute; souhlas se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;dajů nebo pokud už&iacute;v&aacute;me va&scaron;i e-mailovou adresu pro zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sdělen&iacute; v&nbsp;souladu s&nbsp;předchoz&iacute;m čl&aacute;nkem, budou &uacute;daje využity po dobu fungov&aacute;n&iacute; webov&eacute;ho rozhran&iacute;.</p>

<p>&nbsp;</p>

<ol>
<li><strong>VA&Scaron;E PR&Aacute;VA V&nbsp;SOUVISLOSTI S&nbsp;OSOBN&Iacute;MI &Uacute;DAJI</strong>

<ul>
  <li><strong>Pr&aacute;vo na odvol&aacute;n&iacute; souhlasu se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;dajů</strong></li>
</ul>
</li>
</ol>

<p>Pokud zpracov&aacute;v&aacute;me va&scaron;e osobn&iacute; &uacute;daje jen na z&aacute;kladě va&scaron;eho souhlasu (tj. bez jin&eacute;ho z&aacute;konn&eacute;ho důvodu), můžete tento souhlas kdykoliv odvolat.</p>

<p>Odvol&aacute;n&iacute; souhlasu se zpracov&aacute;n&iacute;m va&scaron;ich osobn&iacute;ch &uacute;dajů je možn&eacute; kdykoliv, a to:</p>

<ul>
<li>prostřednictv&iacute;m e-mailu zaslan&eacute;ho na na&scaron;i kontaktn&iacute; e-mailovou adresu;</li>
</ul>

<p>Odvol&aacute;n&iacute;m souhlasu nen&iacute; dotčena z&aacute;konnost zpracov&aacute;n&iacute; &uacute;dajů prov&aacute;děn&eacute;ho do doby odvol&aacute;n&iacute; souhlasu se zpracov&aacute;n&iacute;m.</p>

<ul>
<li><strong>Pr&aacute;vo na př&iacute;stup k osobn&iacute;m &uacute;dajům</strong></li>
</ul>

<p>M&aacute;te pr&aacute;vo n&aacute;s pož&aacute;dat o informaci, zda prov&aacute;d&iacute;me zpracov&aacute;n&iacute; va&scaron;ich osobn&iacute;ch &uacute;dajů. Pokud va&scaron;e &uacute;daje zpracov&aacute;v&aacute;me, m&aacute;te pr&aacute;vo na př&iacute;stup k&nbsp;těmto osobn&iacute;m &uacute;dajům a zejm&eacute;na k&nbsp;n&aacute;sleduj&iacute;c&iacute;m informac&iacute;m:</p>

<ul>
<li>&uacute;čel zpracov&aacute;n&iacute;;</li>
<li>kategorie zpracov&aacute;van&yacute;ch osobn&iacute;ch &uacute;dajů;</li>
<li>př&iacute;jemci nebo kategorie př&iacute;jemců, kter&yacute;m budou osobn&iacute; &uacute;daje zpř&iacute;stupněny;</li>
<li>doba, po kterou budou osobn&iacute; &uacute;daje uloženy.</li>
</ul>

<p>Na va&scaron;i ž&aacute;dost v&aacute;m poskytneme kopii zpracov&aacute;van&yacute;ch &uacute;dajů. Za dal&scaron;&iacute; kopie v&aacute;m můžeme &uacute;čtovat administrativn&iacute; poplatek nepřevy&scaron;uj&iacute;c&iacute; n&aacute;klady spojen&eacute; s&nbsp;vyhotoven&iacute;m a před&aacute;n&iacute;m těchto dal&scaron;&iacute;ch kopi&iacute;.</p>

<ul>
<li><strong>Pr&aacute;vo na opravu</strong></li>
</ul>

<p>Pokud jsou va&scaron;e osobn&iacute; &uacute;daje nepřesn&eacute; nebo ne&uacute;pln&eacute;, m&aacute;te pr&aacute;vo požadovat bezodkladnou n&aacute;pravu, tj. opravu nepřesn&yacute;ch &uacute;dajů a/nebo doplněn&iacute; ne&uacute;pln&yacute;ch &uacute;dajů.</p>

<ul>
<li><strong>Pr&aacute;vo vzn&eacute;st n&aacute;mitku proti zpracov&aacute;n&iacute;</strong></li>
</ul>

<p>M&aacute;te pr&aacute;vo kdykoliv vzn&eacute;st n&aacute;mitku proti zpracov&aacute;n&iacute; va&scaron;ich osobn&iacute;ch &uacute;dajů, pokud je zpracov&aacute;v&aacute;me pro &uacute;čely př&iacute;m&eacute;ho marketingu včetně jak&eacute;hokoliv automatizovan&eacute;ho zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů. Po vznesen&iacute; n&aacute;mitky přestaneme va&scaron;e osobn&iacute; &uacute;daje pro tyto &uacute;čely d&aacute;le zpracov&aacute;vat.</p>

<ul>
<li><strong>Pr&aacute;vo na v&yacute;maz (&bdquo;pr&aacute;vo b&yacute;t zapomenut&ldquo;)</strong></li>
</ul>

<p>M&aacute;te pr&aacute;vo požadovat, abychom va&scaron;e osobn&iacute; &uacute;daje smazali, pokud:</p>

<ul>
<li>osobn&iacute; &uacute;daje již nejsou potřebn&eacute; pro &uacute;čely, pro kter&eacute; byly shrom&aacute;žděny nebo zpracov&aacute;v&aacute;ny;</li>
<li>jste odvolali souhlas se zpracov&aacute;n&iacute;m;</li>
<li>jste vznesli n&aacute;mitky proti zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů;</li>
<li>osobn&iacute; &uacute;daje byly zpracov&aacute;v&aacute;ny protipr&aacute;vně.</li>
</ul>

<p>Pokud neexistuj&iacute; z&aacute;konn&eacute; důvody pro odm&iacute;tnut&iacute; vymaz&aacute;n&iacute;, jsme povinni va&scaron;&iacute; ž&aacute;dosti vyhovět.</p>

<ul>
<li><strong>Pr&aacute;vo na omezen&iacute; zpracov&aacute;n&iacute;</strong></li>
</ul>

<p>M&aacute;te pr&aacute;vo požadovat, abychom omezili zpracov&aacute;n&iacute; va&scaron;ich osobn&iacute;ch &uacute;dajů, pokud:</p>

<ul>
<li>pop&iacute;r&aacute;te přesnost sv&yacute;ch osobn&iacute;ch &uacute;dajů;</li>
<li>je zpracov&aacute;n&iacute; protipr&aacute;vn&iacute; a vy nam&iacute;sto v&yacute;mazu ž&aacute;d&aacute;te o omezen&iacute; zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů;</li>
<li>již va&scaron;e osobn&iacute; &uacute;daje nepotřebujeme pro &uacute;čely zpracov&aacute;n&iacute;, ale vy je požadujete pro určen&iacute;, v&yacute;kon či obhajobu pr&aacute;vn&iacute;ch n&aacute;roků;</li>
<li>vznesete n&aacute;mitku proti zpracov&aacute;n&iacute;.</li>
</ul>

<p>Při omezen&iacute; zpracov&aacute;n&iacute; jsme pouze opr&aacute;vněni va&scaron;e osobn&iacute; &uacute;daje uložit; dal&scaron;&iacute; zpracov&aacute;n&iacute; je možn&eacute; jen s&nbsp;va&scaron;&iacute;m souhlasem, nebo ze z&aacute;konn&yacute;ch důvodů.</p>

<p>Pokud je zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů omezeno z&nbsp;důvodu n&aacute;mitky proti zpracov&aacute;n&iacute;, trv&aacute; omezen&iacute; po dobu nutnou pro zji&scaron;těn&iacute;, zda jsme povinni va&scaron;&iacute; n&aacute;mitce vyhovět.</p>

<p>Pokud je zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů omezeno z&nbsp;důvodu popřen&iacute; přesnosti &uacute;dajů, trv&aacute; omezen&iacute; po dobu ověřen&iacute; přesnosti &uacute;dajů.</p>

<ul>
<li><strong>Pr&aacute;vo na přenositelnost &uacute;dajů</strong></li>
</ul>

<p>M&aacute;te pr&aacute;vo na z&iacute;sk&aacute;n&iacute; va&scaron;ich osobn&iacute;ch &uacute;dajů, kter&eacute; jste n&aacute;m poskytli, ve strukturovan&eacute;m, běžně použ&iacute;van&eacute;m a strojově čiteln&eacute;m form&aacute;tu a předat je jin&eacute;mu spr&aacute;vci osobn&iacute;ch &uacute;dajů.</p>

<ul>
<li><strong>Jak můžete sv&aacute; pr&aacute;va uplatnit?</strong></li>
</ul>

<p>Va&scaron;e pr&aacute;va v&nbsp;souvislosti s&nbsp;osobn&iacute;mi &uacute;daji můžete uplatnit prostřednictv&iacute;m na&scaron;ich kontaktn&iacute;ch &uacute;dajů. Ve&scaron;ker&eacute; informace a &uacute;kony v&aacute;m budou poskytnuty bez zbytečn&eacute;ho odkladu.</p>

<p>Při ochraně va&scaron;ich osobn&iacute;ch &uacute;dajů v&aacute;m vyjdeme maxim&aacute;lně vstř&iacute;c. Pokud ov&scaron;em s&nbsp;vyř&iacute;zen&iacute;m nebudete spokojeni, m&aacute;te pr&aacute;vo obr&aacute;tit se na př&iacute;slu&scaron;n&eacute; org&aacute;ny, zejm&eacute;na na &Uacute;řad pro ochranu osobn&iacute;ch &uacute;dajů (http://www.uoou.cz), kter&yacute; vykon&aacute;v&aacute; dozor nad ochranou osobn&iacute;ch &uacute;dajů. T&iacute;mto ustanoven&iacute;m nen&iacute; dotčeno va&scaron;e opr&aacute;vněn&iacute; obr&aacute;tit se se sv&yacute;m podnětem na &Uacute;řad pro ochranu osobn&iacute;ch &uacute;dajů př&iacute;mo.</p>

<p>Pokud se zejm&eacute;na va&scaron;e bydli&scaron;tě, m&iacute;sto v&yacute;konu zaměstn&aacute;n&iacute; nebo m&iacute;sto &uacute;dajn&eacute;ho poru&scaron;en&iacute; ochrany osobn&iacute;ch &uacute;dajů nach&aacute;z&iacute; mimo Českou republiku v&nbsp;jin&eacute;m člensk&eacute;m st&aacute;tě Evropsk&eacute; unie, můžete se obr&aacute;tit na př&iacute;slu&scaron;n&yacute; dozorov&yacute; &uacute;řad v&nbsp;tomto člensk&eacute;m st&aacute;tě.</p>

<p><strong>&nbsp;</strong></p>

<ol>
<li><strong>SPR&Aacute;VA A ZPRACOV&Aacute;N&Iacute; OSOBN&Iacute;CH &Uacute;DAJŮ</strong>

<ul>
  <li><strong>Jak spravujeme a zpracov&aacute;v&aacute;me va&scaron;e osobn&iacute; &uacute;daje?</strong></li>
</ul>
</li>
</ol>

<p>Jsme spr&aacute;vcem osobn&iacute;ch &uacute;dajů ve smyslu ZOO&Uacute; a jsme vedeni v&nbsp;registru u &Uacute;řadu pro ochranu osobn&iacute;ch &uacute;dajů pod registračn&iacute;m č&iacute;slem 00072333.</p>

<p>Osobn&iacute; i dal&scaron;&iacute; z&iacute;sk&aacute;van&eacute; &uacute;daje jsou plně zabezpečeny proti zneužit&iacute;. Osobn&iacute; &uacute;daje budou zpracov&aacute;v&aacute;ny v elektronick&eacute; podobě automatizovan&yacute;m způsobem nebo v ti&scaron;těn&eacute; podobě neautomatizovan&yacute;m způsobem.</p>

<ul>
<li><strong>Komu před&aacute;v&aacute;me va&scaron;e osobn&iacute; &uacute;daje?</strong></li>
</ul>

<p>V&nbsp;rozsahu, jak&yacute; je nutn&yacute; pro splněn&iacute; smlouvy nebo dal&scaron;&iacute;ch povinnost&iacute;, jsme opr&aacute;vněn&iacute; va&scaron;e osobn&iacute; &uacute;daje před&aacute;vat i dal&scaron;&iacute;m osob&aacute;m (např. dopravcům nebo dal&scaron;&iacute;m osob&aacute;m pod&iacute;lej&iacute;c&iacute;m se na splněn&iacute; smlouvy či na&scaron;ich povinnost&iacute;). Osobn&iacute; &uacute;daje nepřed&aacute;v&aacute;me ž&aacute;dn&eacute; dal&scaron;&iacute; osobě.</p>

<p>Va&scaron;e osobn&iacute; &uacute;daje nebudou před&aacute;v&aacute;ny do st&aacute;tů mimo Evropskou unii.</p>

<p><strong>&nbsp;</strong></p>

<ol>
<li><strong>SOUBORY COOKIES</strong>

<ul>
  <li><strong>Co&nbsp;</strong><strong>jsou to&nbsp;</strong><strong>cookies?</strong></li>
</ul>
</li>
</ol>

<p>Cookies jsou textov&eacute; soubory ukl&aacute;dan&eacute; do poč&iacute;tače nebo jin&eacute;ho elektronick&eacute;ho zař&iacute;zen&iacute; každ&eacute;ho n&aacute;v&scaron;těvn&iacute;ka webov&eacute;ho rozhran&iacute;, kter&eacute; umožňuj&iacute; fungov&aacute;n&iacute; webov&eacute;ho rozhran&iacute;.</p>

<p>Ne v&scaron;echny cookies shromažďuj&iacute; osobn&iacute; &uacute;daje; někter&eacute; pouze umožňuj&iacute; spr&aacute;vn&eacute; fungov&aacute;n&iacute; webov&eacute;ho rozhran&iacute;. Použ&iacute;v&aacute;n&iacute; souborů cookies můžete odm&iacute;tnout volbou v př&iacute;slu&scaron;n&eacute;m nastaven&iacute; v&nbsp;internetov&eacute;m prohl&iacute;žeči.</p>

<p>Upozorňujeme na to, že při odm&iacute;tnut&iacute; použ&iacute;v&aacute;n&iacute; souborů cookies nen&iacute; vyloučeno, že nebudete moci plně využ&iacute;vat ve&scaron;ker&eacute; funkce webov&eacute;ho rozhran&iacute;.</p>

<ul>
<li><strong>Jak&eacute; cookies a&nbsp;</strong><strong>pro jak&eacute; &uacute;čely je webov&eacute; rozhran&iacute; využ&iacute;v&aacute;?</strong></li>
</ul>

<p>Webov&eacute; rozhran&iacute; použ&iacute;v&aacute;&nbsp;<strong>relačn&iacute; (dočasn&eacute;) cookies</strong>, kter&eacute; jsou automaticky maz&aacute;ny po ukončen&iacute; prohl&iacute;žen&iacute; webov&eacute;ho rozhran&iacute;. D&aacute;le využ&iacute;v&aacute;&nbsp;<strong>trval&eacute; cookies</strong>, kter&eacute; zůst&aacute;vaj&iacute; ve va&scaron;em zař&iacute;zen&iacute;, dokud je nesmažete.</p>

<p>Cookies, kter&eacute; využ&iacute;v&aacute; webov&eacute; rozhran&iacute;, jsou n&aacute;sleduj&iacute;c&iacute;:</p>

<ul>
<li><strong>cookies prvn&iacute; strany</strong>&nbsp;&ndash; tyto cookies jsou přiřazeny k&nbsp;dom&eacute;ně na&scaron;eho webu; jedn&aacute; se o nezbytn&eacute; cookies a v&yacute;konnostn&iacute; cookies, mohou b&yacute;t dočasn&eacute; nebo trval&eacute;;

<ul>
  <li><strong>nezbytn&eacute; cookies</strong>&nbsp;&ndash; umožňuj&iacute; navigaci na webov&eacute;m rozhran&iacute; a využ&iacute;v&aacute;n&iacute; z&aacute;kladn&iacute;ch funkc&iacute;, nijak v&aacute;s neidentifikuj&iacute; a nejedn&aacute; se o osobn&iacute; &uacute;daje;</li>
  <li><strong>v&yacute;konnostn&iacute; cookies</strong>&nbsp;&ndash; slouž&iacute; pro anal&yacute;zu způsobu využ&iacute;v&aacute;n&iacute; webov&eacute;ho rozhran&iacute; (počet n&aacute;v&scaron;těv, čas str&aacute;ven&yacute; na webov&eacute;m rozhran&iacute; apod.); &uacute;daje z&iacute;skan&eacute; těmito cookies jsou anonymn&iacute;;</li>
</ul>
</li>
<li><strong>cookies třet&iacute;ch stran</strong>&nbsp;&ndash; tyto cookies jsou přiřazeny k&nbsp;jin&eacute; dom&eacute;ně, než je dom&eacute;na na&scaron;eho webu, a to i když se nach&aacute;z&iacute;te na na&scaron;em webu; tyto cookies n&aacute;m umožňuj&iacute; analyzovat n&aacute;&scaron; web a zobrazovat pro v&aacute;s reklamu na m&iacute;ru; jedn&aacute; se o funkčn&iacute; cookies a c&iacute;len&eacute; a reklamn&iacute; cookies;
<ul>
  <li><strong>funkčn&iacute; cookies</strong>&nbsp;&ndash; slouž&iacute; k&nbsp;personalizaci obsahu prostřednictv&iacute;m zapamatov&aacute;n&iacute; přihla&scaron;ovac&iacute;ch &uacute;dajů, geolokace apod.; jejich prostřednictv&iacute;m může doch&aacute;zet k z&iacute;sk&aacute;v&aacute;n&iacute; a zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů;</li>
  <li><strong>c&iacute;len&eacute; a reklamn&iacute; cookies</strong>&nbsp;&ndash; slouž&iacute; k&nbsp;zobrazov&aacute;n&iacute; c&iacute;len&yacute;ch reklam na webov&eacute;m rozhran&iacute; i mimo ně; jejich prostřednictv&iacute;m může doch&aacute;zet k z&iacute;sk&aacute;v&aacute;n&iacute; a zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;dajů. Informace o tom, jak n&aacute;&scaron; web využ&iacute;v&aacute;te, můžeme d&aacute;le sd&iacute;let s&nbsp;na&scaron;imi partnery z&nbsp;oblasti soci&aacute;ln&iacute;ch s&iacute;t&iacute;, inzerce a anal&yacute;z.</li>
  <li><strong>Použ&iacute;van&eacute; služby společnosti Google</strong></li>
</ul>
</li>
</ul>

<p>Webov&eacute; rozhran&iacute; použ&iacute;v&aacute; službu Google Analytics a př&iacute;padně i dal&scaron;&iacute; služby poskytovan&eacute; společnost&iacute; Google, Inc (d&aacute;le jen &bdquo;<strong>Google</strong>&ldquo;). Tyto služby pracuj&iacute; s&nbsp;informacemi z&iacute;skan&yacute;mi prostřednictv&iacute;m souborů cookies.</p>

<p>Pokud v&aacute;s zaj&iacute;m&aacute;, jak Google využ&iacute;v&aacute; data, kter&eacute; od n&aacute;s z&iacute;sk&aacute;v&aacute;, a jak zpracov&aacute;n&iacute; upravit nebo zak&aacute;zat, dozv&iacute;te se tyto informace kliknut&iacute;m na n&aacute;sleduj&iacute;c&iacute; odkaz:&nbsp;<a href="https://policies.google.com/technologies/partner-sites?hl=cs" target="_blank" rel="noopener noreferrer">Jak Google využ&iacute;v&aacute; data, když použ&iacute;v&aacute;te weby nebo aplikace na&scaron;ich partnerů.&nbsp;</a></p>

<p>Tyto Z&aacute;sady jsou platn&eacute; a &uacute;činn&eacute; od 25.5.2018</p>
	     </div>
    );
  }

}
