import React from "react";

import Header from '../header';
import Footer from '../footer';

// Profile data
import MainArticleData from '../components/data/MainArticleData'
import ProfileDetailComponent from './sections/about/ProfileDetailComponent';

// Photos

// Location photots
import Bohunice from '../images/locations/Bohunice.jpg';
import Sobesice from '../images/locations/Sobesice.jpg';
import Kohoutovice from '../images/locations/Brno-Kohoutovice.jpg';
import Jihlava from '../images/locations/Jihlava.jpg';
import Kunstat from '../images/locations/Kunstat.jpg';
import Lednice from '../images/locations/Lednice.jpg';
import VelkaBites from '../images/locations/Velka-bites.jpg';


const trainingLocation = [
  // Kraj Vysocina
  {
     city: 'rantirov',
     contactName: 'Jakub Uherek',
     contactPhone: '722 926 131',
     email: 'just4keeperscz@gmail.com',

     locations : [
     {
       location: 'Rantířov',
       contactName2: 'Martin Jícha',
       contactPhone2: '736 515 979',
       contactName: 'Jakub Uherek',
       contactPhone: '722 926 131',
       address: 'Rantířov 109, 588 41 Rantířov',
       days: [
         {
           day: 'Úterý:',
           timeSlot: '17:00 - 18:00',
           category: ''
         }
       ],
       photo: Jihlava
     }
    ]

   },

   {
      city: 'luka-nad-jihlavou',
      contactName: 'Jakub Uherek',
      contactPhone: '722 926 131',
      email: 'just4keeperscz@gmail.com',

      locations : [
      {
        location: 'Luka nad Jihlavou',
        contactName2: 'Martin Jícha',
        contactPhone2: '736 515 979',
        contactName: 'Jakub Uherek',
        contactPhone: '722 926 131',
        address: 'Luka nad Jihlavou',
        days: [
          {
            day: 'Středa:',
            timeSlot: '17:00 - 18:00',
            category: ''
          }
        ],
        photo: Jihlava
      }
     ]

    },

// zanikla
    {
       city: 'velka-bites',
       contactName: 'Mgr. Martin Jícha',
       contactPhone: '731 575 400',
       email: 'just4keeperscz@gmail.com',

       locations : [
       {
         location: 'Velká Bíteš',
         contactName: 'Mgr. Martin Jícha',
         contactPhone: '731 575 400',
         address: 'Vlkovská ulice',
         days: [
           {
             day: 'Středa:',
             timeSlot: '16:30-17:30',
             category: 'U9 - U14'
           }
         ],
         photo: VelkaBites
       }
     ]

     },

     // zanikla
     {
        city: 'chotebor',
        contactName: 'Bc. Tomáš Jícha',
        contactPhone: '734 173 342',
        email: 'just4keeperscz@gmail.com',

        locations : [
        {
          location: 'Chotěboř',
          contactName: 'Tomáš Jícha',
          contactPhone: '734 173 342',
          address: 'Chotěboř',
          days: [
            {
              day: 'Pondělí:',
              timeSlot: '15:30-16:30',
              category: ''
            }
          ]
        }
       ]

      },
      {
         city: 'zdirec-nad-doubravou',
         contactName: 'Mgr. Tomáš Jícha',
         contactPhone: '734 173 342',
         email: 'just4keeperscz@gmail.com',

         locations : [
         {
           location: 'Ždírec nad Doubravou',
           contactName: 'Tomáš Jícha',
           contactPhone: '734 173 342',
           address: 'Ždírec nad Doubravou',
           days: [
             {
               day: 'Úterý:',
               timeSlot: '15:30-16:30',
               category: ''
             }
           ]
         }
        ]

       },
       {
          city: 'zdar-nad-sazavou',
          contactName: 'Mgr. Tomáš Jícha',
          contactPhone: '734 173 342',
          email: 'just4keeperscz@gmail.com',

          locations : [
          {
            location: 'Žďár nad Sázavou',
            contactName: 'Mgr. Tomáš Jícha',
            contactPhone: '734 173 342',
            address: 'Žďár nad Sázavou',
            days: [
              {
                day: 'Úterý:',
                timeSlot: '17:00 - 18:00',
                category: 'Přípravka, žáci'
              },
              {
                day: 'Pátek:',
                timeSlot: '15:30 - 16:30',
                category: 'Přípravka, žáci'
              },
              {
                day: 'Pátek:',
                timeSlot: '16:30 - 17:30',
                category: 'Dorost'
              }
            ]
          }
         ]

        },
       {
          city: 'velke-mezirici',
          contactName: 'Mgr. Tomáš Jícha',
          contactPhone: '734 173 342',
          email: 'just4keeperscz@gmail.com',

          locations : [
          {
            location: 'Velké Meziříčí',
            contactName: 'Mgr. Tomáš Jícha',
            contactPhone: '734 173 342',
            address: 'Velké Meziříčí',
            days: [
              {
                day: 'Čtvrtek:',
                timeSlot: '15:00 - 16:00',
                category: ''
              }
            ]
          }
         ]

        },
        {
           city: 'humpolec',
           contactName: 'Adam Prchal',
           contactPhone: '725 909 309',
           email: 'just4keeperscz@gmail.com',

           locations : [
           {
             location: 'Humpolec',
             contactName: 'Adam Prchal',
             contactPhone: '725 909 309',
             address: 'Humpolec',
             days: [
               {
                 day: 'Den bude upřesněn:',
                 timeSlot: 'Čas bude upřesněn',
                 category: ''
               }
             ]
           }
          ]
         },
         {
            city: 'pelhrimov',
            contactName: 'Adam Prchal',
            contactPhone: '725 909 309',
            email: 'just4keeperscz@gmail.com',

            locations : [
            {
              location: 'Pelhřimov',
              contactName: 'Adam Prchal',
              contactPhone: '725 909 309',
              address: 'Pelhřimov',
              days: [
                {
                  day: 'Den bude upřesněn:',
                  timeSlot: 'Čas bude upřesněn',
                  category: ''
                }
              ]
            }
           ]
          },

         {
            city: 'trest',
            contactName: 'Mgr. Tomáš Jícha',
            contactPhone: '734 173 342',
            email: 'just4keeperscz@gmail.com',

            locations : [
            {
              location: 'Třešť',
              contactName: 'Mgr. Tomáš Jícha',
              contactPhone: '734 173 342',
              address: 'Třešť',
              days: [
                {
                  day: 'Pátek:',
                  timeSlot: '15:00 - 16:00',
                  category: ''
                }
              ]
            }
           ]

          },
           // zanikla
        {
           city: 'trebic',
           contactName: 'Bc. Tomáš Jícha',
           contactPhone: '734 173 342',
           email: 'just4keeperscz@gmail.com',

           locations : [
           {
             location: 'Třebíč',
             contactName: 'Tomáš Jícha',
             contactPhone: '734 173 342',
             address: 'Třebíč',
             days: [
               {
                 day: 'Pátek:',
                 timeSlot: '16:00-17:00',
                 category: ''
               }
             ]
           }
          ]

         },

         {
            city: 'namest-nad-oslavou',
            contactName: 'Mgr. Tomáš Jícha',
            contactPhone: '734 173 342',
            email: 'just4keeperscz@gmail.com',

            locations : [
            {
              location: 'Náměšť nad Oslavou',
              contactName: 'Tomáš Jícha',
              contactPhone: '734 173 342',
              address: 'Náměšť nad Oslavou',
              days: [
                {
                  day: 'čtvrtek:',
                  timeSlot: '16:45 - 17:45',
                  category: ''
                }
              ]
            }
           ]

          },

           // Jihomoravsky kraj
           {
              city: 'brno',
              contactName: 'Mgr. Martin Jícha',
              contactPhone: '731 575 400',
              email: 'just4keeperscz@gmail.com',

              locations : [

              {
                location: 'Brno - Soběšice',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                address: 'Borová 510/30, Brno sever',
                days: [
                  {
                    day: 'Pondělí:',
                    timeSlot: '16:15 - 17:15',
                    category: ''
                  },

                ],
                photo: Sobesice
              },
              {
                location: 'Brno - Bohunice',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                address: 'Tatran Bohunice - Neužilova 201/35',
                days: [
                  {
                    day: 'Pondělí:',
                    timeSlot: '16:30 - 17:30',
                    category: ''
                  },
                  {
                    day: 'Pondělí:',
                    timeSlot: '17:15 - 18:15',
                    category: ''
                  },
                  {
                    day: 'Pondělí:',
                    timeSlot: '18:00 - 19:00',
                    category: ''
                  },
                ],
                photo: Bohunice
              },
              {
                location: 'Brno - Kohoutovice',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                address: 'Voříškova 864/59 Brno - Kohoutovice',
                days: [
                  {
                    day: 'Pondělí:',
                    timeSlot: '15:00 - 16:00',
                    category: ''
                  },

                ],
                photo: Kohoutovice
              },
              {
                location: 'Brno - Moravská Slavia',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                address: '',
                days: [
                  {
                    day: 'Středa:',
                    timeSlot: '17:15 - 18:15',
                    category: ''
                  },

                ],
                photo: Kohoutovice
              },
              {
                location: 'Brno - Nový Lískovec',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                address: 'Nový Lískovec',
                days: [
                  {
                    day: 'Úterý:',
                    timeSlot: '16:15 - 17:15',
                    category: ''
                  },

                ],

              },

              {
                location: 'Líšeň ',
                contactName: 'Mgr. Tomáš Jícha',
                contactPhone: '734 173 342',
                address: 'Líšeň ',
                days: [
                  {
                    day: 'Pondělí:',
                    timeSlot: '16:00 - 17:00',
                    category: ''
                  },
                  {
                    day: 'Středa:',
                    timeSlot: '16:00 - 17:00',
                    category: ''
                  },

                ],
              },

              {
                location: 'Svratka  ',
                contactName: 'Pavel Auředník',
                contactPhone: '602 530 328',
                address: 'Svratka ',
                days: [
                  {
                    day: 'čtvrtek:',
                    timeSlot: '16:00 - 17:00',
                    category: ''
                  },
                  {
                    day: 'čtvrtek:',
                    timeSlot: '17:00 - 18:00',
                    category: ''
                  },

                ],
              }

              ]
            },

            {
               city: 'blansko',
               contactName: 'Mgr. Martin Jícha',
               contactPhone: '731 575 400',
               email: 'just4keeperscz@gmail.com',

               locations : [
               {
                 location: 'Blansko',
                 contactName: 'Mgr. Martin Jícha',
                 contactPhone: '731 575 400',
                 address: 'Blansko, Mlýnská 18',
                 days: [
                   {
                     day: 'Den a cas bude upresnen:',
                     timeSlot: '',
                     category: ''
                   }
                 ]
               }
               ]
             },

             {
                city: 'kunstat',
                contactName: 'Mgr. Martin Jícha',
                contactPhone: '731 575 400',
                email: 'just4keeperscz@gmail.com',

                locations : [
                {
                  location: 'Kunštát',
                  contactName: 'Mgr. Martin Jícha',
                  contactPhone: '731 575 400',
                  address: 'Kunštát',
                  days: [
                    {
                      day: 'Čtvrtek:',
                      timeSlot: '16:30 - 17:30',
                      category: 'U10 - U14'
                    }
                  ],
                  photo: Kunstat
                }

                ]
              },

              {
                 city: 'kninice-u-boskovic',
                 contactName: 'Mgr. Martin Jícha',
                 contactPhone: '731 575 400',
                 email: 'just4keeperscz@gmail.com',

                 locations : [
                 {
                   location: 'Knínice u Boskovic',
                   contactName: 'Mgr. Martin Jícha',
                   contactPhone: '731 575 400',
                   address: 'Knínice u Boskovic',
                   days: [
                     {
                       day: 'Pátek:',
                       timeSlot: '16:45 - 17:45',
                       category: ''
                     },

                   ]
                 }

                 ]
               },

               {
                  city: 'bystre',
                  contactName: 'Mgr. Martin Jícha',
                  contactPhone: '731 575 400',
                  email: 'just4keeperscz@gmail.com',

                  locations : [
                  {
                    location: 'Bystré ',
                    contactName: 'Mgr. Martin Jícha',
                    contactPhone: '731 575 400',
                    address: 'Bystré ',
                    days: [
                      {
                        day: 'Pátek :',
                        timeSlot: '15:00 - 16:00',
                        category: ''
                      }
                    ],

                  }

                  ]
                },

                {
                   city: 'hustopece',
                   contactName: 'Pavel Auředník',
                   contactPhone: '602 530 328',
                   email: 'just4keeperscz@gmail.com',

                   locations : [
                   {
                     location: 'Hustopeče ',
                     contactName: 'Pavel Auředník',
                     contactPhone: '602 530 328',
                     address: 'Hustopeče ',
                     days: [
                       {
                         day: 'Pondělí:',
                         timeSlot: '16:30 - 17:30',
                         category: ''
                       },
                       {
                         day: 'Pondělí:',
                         timeSlot: '17:30 - 18:30',
                         category: ''
                       }
                     ],
                     photo: Lednice
                   }

                   ]
                 },

                 {
                    city: 'breclav',
                    contactName: 'Pavel Auředník',
                    contactPhone: '602 530 328',
                    email: 'just4keeperscz@gmail.com',

                    locations : [
                    {
                      location: 'Břeclav ',
                      contactName: 'Pavel Auředník',
                      contactPhone: '602 530 328',
                      address: 'Břeclav ',
                      days: [
                        {
                          day: 'úterý:',
                          timeSlot: '16:00 - 17:00',
                          category: ''
                        },
                        {
                          day: 'úterý:',
                          timeSlot: '17:30 - 18:30',
                          category: ''
                        }
                      ],
                      photo: Lednice
                    }

                    ]
                  },

                  {
                     city: 'lanzhot',
                     contactName: 'Pavel Auředník',
                     contactPhone: '602 530 328',
                     email: 'just4keeperscz@gmail.com',

                     locations : [
                     {
                       location: 'Lanžhot  ',
                       contactName: 'Pavel Auředník',
                       contactPhone: '602 530 328',
                       address: 'Lanžhot  ',
                       days: [
                         {
                           day: 'středa:',
                           timeSlot: '16:00 - 17:00',
                           category: ''
                         },

                       ],
                       photo: Lednice
                     }

                     ]
                   },

                   {
                      city: 'tisnov',
                      contactName: 'Mgr. Tomáš Jícha',
                      contactPhone: '734 173 342',
                      email: 'just4keeperscz@gmail.com',

                      locations : [
                      {
                        location: 'Tišnov ',
                        contactName: 'Tomáš Jícha',
                        contactPhone: '734 173 342',
                        address: 'Tišnov ',
                        days: [
                          {
                            day: 'středa:',
                            timeSlot: '18:00 - 19:00',
                            category: ''
                          }
                        ]
                      }
                     ]

                    },

               // zanikla
               {
                  city: 'lednice',
                  contactName: 'Pavel Auředník',
                  contactPhone: '602 530 328',
                  email: 'just4keeperscz@gmail.com',

                  locations : [
                  {
                    location: 'Lednice',
                    contactName: 'Pavel Auředník',
                    contactPhone: '602 530 328',
                    address: 'Slovácká 123, Lednice',
                    days: [
                      {
                        day: 'Pondělí:',
                        timeSlot: '16:00-17:30',
                        category: 'U9 - U14'
                      }
                    ],
                    photo: Lednice
                  }

                  ]
                },

                // Zlynsky Vysocina
                {
                   city: 'uhersky-brod',
                   contactName: 'Vít Nemrava',
                   contactPhone: ' ',
                   email: 'just4keeperscz@gmail.com',

                   locations : [
                   {
                     location: 'Uherský Brod',
                     contactName: 'Vít Nemrava',
                     contactPhone: ' ',
                     address: 'Orelský stadion - Uherský Brod',
                     days: [
                       {
                         day: 'Úterý:',
                         timeSlot: '16:00',
                         category: 'starší kategorie'
                       },
                       {
                         day: 'Středa:',
                         timeSlot: '16:00',
                         category: 'mladší kategorie'
                       }
                     ],

                   }
                  ]

                 },


]

export default class TrainingLocations extends React.Component {

  render() {

    const cityName = this.props.match.params.city
    let locationData = this.getDataByLocation(cityName);

    const contactsData = new MainArticleData();

    return (
	     <div>
		        <Header />
             <div className="view trainingLocationArticle">
            <div className="indent">
              <h2>Rezervujte si brankářský trénink  ZDARMA na:</h2>
              <p>tel.: {locationData.contactName} <b>{locationData.contactPhone}</b></p>
              <p>email: <b>just4keeperscz@gmail.com</b></p>
            </div>

            <h2>Kde a kdy trénujeme?</h2>
              <div className="indent">
                {locationData.locations.map((item, index) => (
                  <div key={"trainingLocation" + index} className="trainingLocationArticle">
                    <p><b>{item.location}</b></p>
                    { item.days.map((item, index) => (
                      <div key={"trainingLocationTimeSlot" + index}>
                        {item.day} {item.timeSlot} <b>{item.category}</b>
                      </div>
                      ))
                     }

                    <div>Kontakt: {item.contactName} {item.contactPhone} { item.contactName2 && item.contactName2.length > 0 ? ', ' : ''} {item.contactName2} {item.contactPhone2}</div>
                    <div>Adresa: {item.address}</div>

                  {item.photo !== undefined ? <img src={item.photo} alt="Trénink"  className="center"/> : ''}

                  </div>

                ))
                }

              </div>

              <h2>Informace o trenérovi</h2>
              <ProfileDetailComponent trainer={contactsData.getContact(locationData.contactName)} visibility= 'show' />

              <br/>

              </div>
            <Footer />
	     </div>
    );
  }

  getDataByLocation(location) {
    return trainingLocation.filter(a => a.city === location)[0];
  }

}
