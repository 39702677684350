/**  Helper for News */
import firebase from '../../database/firestore';


export function loadTrainingVideoAndRender(thisObject) {
        const db = firebase.firestore();

        db.collection('section_training_videos')
          .get()
          .then(querySnapshot => {
              const data = querySnapshot.docs.map(doc => doc.data());
              // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
              thisObject.setState({videoData: data[0], isLoading: false});
        });
}
