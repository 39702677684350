import React from 'react';
import { HashRouter, NavLink } from "react-router-dom";

// Burger menu
import Popup from "reactjs-popup";
import BurgerIcon from "./components/BurgerIcon";
import Menu from "./components/Menu";
import MainMenu from "./components/MainMenu";
import "./css/burger-menu.css";

import logo from './images/logo/logo.jpg';

function Header() {


	const contentMenuStyle = {
		background: "rgba(255,255,255,0",
		border: "none",
		width: "100%"
	};

	const overlayStyle = {
	  background: "#222",
		position: "relative",
		borderRadius: "0px 0px 20px 20px",
		width: "100%"

	};


  return(
    <header>
      <section>

				<div className="header__container">
					<HashRouter>
						<NavLink to="/">
							<div className="header__column">
								<img src={logo} alt="Just4Keepers" />
							</div>
						</NavLink>
					</HashRouter>

					<MainMenu />

				<div className="menu">
					<Popup
						modal
						overlayStyle={overlayStyle}
						contentStyle={contentMenuStyle}
						closeOnDocumentClick={false}
						trigger={open => <BurgerIcon open={open} />} // this line cause the error
					>
						{close => <Menu close={close} />}
					</Popup>
				</div>
		</div>

      </section>
    </header>
  )
}

export default Header;
