import React from 'react';
import firebase from '../../../database/firestore';

// Forms
import {Formik} from 'formik';
import {Form, Button, Col} from 'react-bootstrap';
import "../../../css/bootstrap.scss";
// Form validation
import * as yup from 'yup';

// Notification pop up
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

 const defaultUser = 'Martin Jícha';
 const defaultButtonText = 'Číst dále';


const schema = yup.object({
  title: yup.string().required('Nadpis je povinný údaj')
});

const newsPath = "news/";
const welcomePhotoPath = "/welcome/";
const storage = firebase.storage();

class NewsForm extends React.Component {

  constructor() {
   super();
   this.applicantForm = React.createRef();

   this.state = {
     image: null,
     url: '',
     progress: 0,

     photoGalleryImages: [],
     photoGalleryProgress: 0,
     photoGalleryUploadCount: 0
   }
   // welcome photo
   this.handleImageChange = this.handleImageChange.bind(this);
   this.handleImageUpload = this.handleImageUpload.bind(this);
   // photo Gallery
   this.handlePhotoInGalleryChange = this.handlePhotoInGalleryChange.bind(this);
   this.handlePhotoGalleryUpload = this.handlePhotoGalleryUpload.bind(this);

 }

 handleImageChange = e => {
   if (e.target.files[0]) {
     const image = e.target.files[0];
     this.setState(() => ({image}));
   }
 }

 handlePhotoInGalleryChange = e => {
   if (e.target.files) {
     this.setState({photoGalleryImages: e.target.files});
   }
 }

 handleImageUpload = (photoPath) => {
     const {image} = this.state;
     const destinationPath = newsPath + photoPath + welcomePhotoPath;
     const uploadTask = storage.ref(`${destinationPath + image.name}`).put(image);
     uploadTask.on('state_changed',
     (snapshot) => {
       // progrss function ....
       const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
       this.setState({progress});
     },
     (error) => {
        // error function ....
       console.log(error);
     },
   () => {
     // success function
     uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
       console.log('File available at', downloadURL);
       //this.setState({url: downloadURL});
     });
  })
  }

  handlePhotoGalleryUpload = (photoPath) => {
      const destinationPath = newsPath + photoPath + "/";
      const {photoGalleryImages, photoGalleryUploadCount} = this.state;
      console.log("photoGalleryImages:");
      console.log(photoGalleryImages);
      let numberOfImagesToUpload = photoGalleryImages.length;
      for (let i = 0; i < numberOfImagesToUpload; i++) {
        let image = photoGalleryImages[i];
        console.log("Uploading: " + image);
        storage.ref(`${destinationPath + image.name}`)
        .put(image)
        .on('state_changed',
        (snapshot) => {
          // progrss function ....          
        },
        (error) => {
          // error function ....
          console.log(error);
        },
        () => {
          // success function
          console.log("Uploaded count: " + (i +1));
          let currentGalleryProgress = Math.round( ((i+1) / numberOfImagesToUpload) * 100);
          this.setState({photoGalleryUploadCount: photoGalleryUploadCount + 1, photoGalleryProgress: currentGalleryProgress});
        })
      }
 }



  saveNewsArticle(values) {
    const db = firebase.firestore();
    db.settings({
      //timestampsInSnapshots: true // without this, we get a warning in the console, which tells us the timestamps stored in Firestore will be read back as timestamp objects, as opposed to system date objects.
    });

    let hashTagsArray = values.hashTags !== undefined ? values.hashTags.replace(' ', '').split('#') : [];
    let hashTags = [];
    hashTagsArray.filter(tag => tag.length > 0).map(tag => (
      hashTags.push({name: tag})
    ));

    let youtubeIdsArray = values.youtubeIds !== undefined ? values.youtubeIds.split(';') : [];

    let descriptionsArray = [];
    let paragraphsArray = [];
    let headlineText = '';
    if(values.paragraphs1 !== undefined) {
      headlineText = values.headline1 !== undefined ? values.headline1 : '';
      let paragraphTexts = [];
      let paragraphsArray = values.paragraphs1.split('\n');
      paragraphsArray.map(paragraph => (
        paragraphTexts.push({text: paragraph.replace('[link]', ''), link: paragraph.includes('[link]')})
      ));
      descriptionsArray.push({headline: headlineText, paragraphs: paragraphTexts });
    }

    if(values.paragraphs2 !== undefined) {
      headlineText = values.headline2 !== undefined ? values.headline2 : '';
      paragraphsArray = values.paragraphs2.split('\n');
      let paragraphTexts2 = [];
      paragraphsArray.map(paragraph => (
        paragraphTexts2.push({text: paragraph.replace('[link]', ''), link: paragraph.includes('[link]')})
      ));
      descriptionsArray.push({headline: headlineText, paragraphs: paragraphTexts2 });
    }

    if(values.paragraphs3 !== undefined) {
      headlineText = values.headline3 !== undefined ? values.headline3 : '';
      paragraphsArray = values.paragraphs3.split('\n');
      let paragraphTexts3 = [];
      paragraphsArray.map(paragraph => (
        paragraphTexts3.push({text: paragraph.replace('[link]', ''), link: paragraph.startsWith('[link]')})
      ));
      descriptionsArray.push({headline: headlineText, paragraphs: paragraphTexts3 });
    }

    if(values.paragraphs4 !== undefined) {
      headlineText = values.headline4 !== undefined ? values.headline4 : '';
      paragraphsArray = values.paragraphs4.split('\n');
      let paragraphTexts4 = [];
      paragraphsArray.map(paragraph => (
        paragraphTexts4.push({text: paragraph.replace('[link]', ''), link: paragraph.startsWith('[link]')})
      ));
      descriptionsArray.push({headline: headlineText, paragraphs: paragraphTexts4 });
    }

    let socialMediaLinksMap = [];
    if(values.instagramLink) {
      socialMediaLinksMap.push({media: 'Instagram', value: 'Just4keepersCZ'});
    }

    if(values.facebookLink) {
      socialMediaLinksMap.push({media: 'Fb', value: 'Just4keepers CZ'});
    }

    if(values.webLink) {
      socialMediaLinksMap.push({media: 'Web', value: 'j4k.cz'});
    }

    let webPage = values.webPageLink ? 'j4k.cz' : '';
    let eshop = values.eshopLink ? 'j4ksports.cz' : '';

    db.collection(newsPath)
      .doc(values.title)
      .set({
        title: values.title,
        sliderDescription: values.sliderDescription,
        user: values.user,
        date: values.test ? new Date(1000) : new Date(),
        button: values.button,
        goalKeepersDay: values.goalKeepersDay,
        hasGallery: this.state.photoGalleryImages.length > 0,
        descriptions: descriptionsArray,
        hashTags: hashTags,
        videoYoutubeIds: youtubeIdsArray,
        webPageLink: webPage,
        eshopLink: eshop,
        socialMediaLinks: socialMediaLinksMap,
        uid: values.uid})
      .then(() => {
        console.log("Sucessfull insert into applications path");
        let notificationOptions = {
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        };

        this.handleImageUpload(values.title);
        this.handlePhotoGalleryUpload(values.title);

        toast.success("Zpráva úspěšně uložena", notificationOptions);
       }).catch(error => {
         let errorNotificationOptions = {
           autoClose: false,
           type: toast.TYPE.ERROR
         };
         toast.error("Uložení zprávy se nezdařilo, zkuste to prosím později.", errorNotificationOptions);
        console.log(error);
    });
  };

  render() {


    return (
      <div id='applicationForm' className="use-bootstrap">
         <h4>Slider info</h4>
        <Formik
             validationSchema={schema}
             onSubmit={ values => this.saveNewsArticle(values) }
             initialValues={{
               user: defaultUser,
               button: defaultButtonText,
               sliderDescription: '',
               uid: '',

               webPageLink: false,
               eshopLink: false,
               instagramLink: false,
               facebookLink: false,

               goalKeepersDay: false,
               hasGallery: false
             }}
           >
             {({
               handleSubmit,
               handleChange,
               handleBlur,
               values,
               touched,
               isValid,
               errors
             }) => (
               <Form noValidate onSubmit={handleSubmit}
                                ref={this.applicantForm}>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormik01">
                     <Form.Label>Nadpis</Form.Label>
                      <Form.Control
                         type="text"
                         placeholder="Nadpis zprávy"
                         name="title"
                         value={values.title}
                         onChange={handleChange}
                         isInvalid={!!errors.title}
                         isValid={errors.title}
                      />

                      <Form.Control.Feedback type="invalid">
                         {errors.title}
                      </Form.Control.Feedback>
                   </Form.Group>

                   <Form.Group as={Col} md="4" controlId="validationFormik02">
                     <Form.Label>Popis slideru</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder=""
                       name="sliderDescription"
                       value={values.sliderDescription}
                       onChange={handleChange}
                       isInvalid={!!errors.sliderDescription}
                     />

                     <Form.Control.Feedback type="invalid">
                        {errors.sliderDescription}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <h4 className="left">Úvodní fotka</h4>
                   <Form.Group as={Col} md="4" controlId="validationFormikWelcomePhoto">
                     <Form.Label>Vyberte úvodní fotku</Form.Label>
                     <Form.Control
                         type="file"
                         name="welcomePhoto"
                         onChange={this.handleImageChange}
                     />

                   </Form.Group>
                   <Form.Group as={Col} md="4" controlId="validationFormikWelcomePhotoProgress">
                     <progress value={this.state.progress} max="100"/>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <h4 className="left">Galerie fotek</h4>
                   <Form.Group as={Col} md="4" controlId="validationFormikPhotoGallery">
                     <Form.Label>Vyberte fotky do galerie</Form.Label>
                     <Form.Control
                         type="file"
                         multiple
                         name="photoGallery"
                         onChange={this.handlePhotoInGalleryChange}
                     />
                   </Form.Group>

                   <Form.Group as={Col} md="4" controlId="validationFormikGalleryProgress">
                     <progress value={this.state.photoGalleryProgress} max="100"/>
                     <div>Počet nahraných fotek v galerii: {this.state.photoGalleryUploadCount}</div>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <h4 className="left">Odstavce</h4>
                   <Form.Group as={Col} md="4" controlId="validationFormikHL1">
                     <Form.Label>Odstavce pro nadpis číslo 1</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Nadpis odstavce číslo 1"
                       name="headline1"
                       value={values.headline1}
                       onChange={handleChange}
                       isInvalid={!!errors.headline1}
                     />

                     <Form.Control.Feedback type="invalid">
                       {errors.headline1}
                     </Form.Control.Feedback>
                   </Form.Group>
                   <Form.Group md="4" controlId="validationFormik04">
                      <Form.Label>Odstavce pro nadpis číslo 1</Form.Label>
                      <Form.Control as="textarea"
                        rows="10"
                        placeholder="Pokud se sem celý text nevejde, použijte blok číslo 2 níže."
                        name="paragraphs1"
                        value={values.paragraphs1}
                        onChange={handleChange}
                        isInvalid={!!errors.paragraphs1}
                      />

                     <Form.Control.Feedback type="invalid">
                       {errors.paragraphs1}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormikHL1">
                     <Form.Label>Odstavce pro nadpis číslo 2</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Nadpis odstavce číslo 2"
                       name="headline2"
                       value={values.headline2}
                       onChange={handleChange}
                       isInvalid={!!errors.headline2}
                     />

                     <Form.Control.Feedback type="invalid">
                       {errors.headline2}
                     </Form.Control.Feedback>
                   </Form.Group>
                   <Form.Group md="4" controlId="validationFormik04">
                      <Form.Label>Odstavce pro nadpis číslo 2</Form.Label>
                      <Form.Control as="textarea"
                        rows="10"
                        placeholder="Pokud se sem celý text nevejde, použijte blok číslo 3 níže."
                        name="paragraphs2"
                        value={values.paragraphs2}
                        onChange={handleChange}
                        isInvalid={!!errors.paragraphs2}
                      />

                     <Form.Control.Feedback type="invalid">
                       {errors.paragraphs2}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormikHL1">
                     <Form.Label>Odstavce pro nadpis číslo 3</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Nadpis odstavce číslo 3"
                       name="headline3"
                       value={values.headline3}
                       onChange={handleChange}
                       isInvalid={!!errors.headline3}
                     />

                     <Form.Control.Feedback type="invalid">
                       {errors.headline3}
                     </Form.Control.Feedback>
                   </Form.Group>
                   <Form.Group md="4" controlId="validationFormik04">
                      <Form.Label>Odstavce pro nadpis číslo 3</Form.Label>
                      <Form.Control as="textarea"
                        rows="10"
                        placeholder="Pokud se sem celý text nevejde, použijte blok číslo 4 níže."
                        name="paragraphs3"
                        value={values.paragraphs3}
                        onChange={handleChange}
                        isInvalid={!!errors.paragraphs3}
                      />

                     <Form.Control.Feedback type="invalid">
                       {errors.paragraphs3}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormikLH4">
                     <Form.Label>Odstavce pro nadpis číslo 4</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Nadpis odstavce číslo 4"
                       name="headline4"
                       value={values.headline4}
                       onChange={handleChange}
                       isInvalid={!!errors.headline4}
                     />

                     <Form.Control.Feedback type="invalid">
                       {errors.headline4}
                     </Form.Control.Feedback>
                   </Form.Group>
                   <Form.Group md="4" controlId="validationFormik04">
                      <Form.Label>Odstavce pro nadpis číslo 4</Form.Label>
                      <Form.Control as="textarea"
                        rows="10"
                        placeholder=""
                        name="paragraphs4"
                        value={values.paragraphs4}
                        onChange={handleChange}
                        isInvalid={!!errors.paragraphs4}
                      />

                     <Form.Control.Feedback type="invalid">
                       {errors.paragraphs4}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                <Form.Row>
                  <h4 className="left">Sociální sítě</h4>
                  <Form.Group as={Col} md="8" controlId="validationFormik07">
                    <Form.Label>Facebook Hash tags</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="hashtagy např. #J4KRodina #Just4keepers"
                      name="hashTags"
                      value={values.hashTags}
                      onChange={handleChange}
                      isInvalid={!!errors.hashTags}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.hashTags}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <h4 className="left">Youtube videa</h4>
                  <Form.Group as={Col} md="8" controlId="validationFormik077">
                    <Form.Label>Youtube videa</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="videaId oddělené středníkem např. kSmf3dZ8Y4E;qymi0uOzfRI"
                      name="youtubeIds"
                      value={values.youtubeIds}
                      onChange={handleChange}
                      isInvalid={!!errors.youtubeIds}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.youtubeIds}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <h4 className="left">Odkazy</h4>
                <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="webPageLink"
                        label="Odkaz na webovou stránku"
                        onChange={handleChange}
                        isInvalid={!!errors.webPageLink}
                        feedback={errors.webPageLink}
                        id="validationFormik0"
                      />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group>
                    <Form.Check
                      name="eshopLink"
                      label="Odkaz na eshop"
                      onChange={handleChange}
                      isInvalid={!!errors.eshopLink}
                      feedback={errors.eshopLink}
                      id="validationFormikEshop"
                      />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group>
                    <Form.Check
                      name="instagramLink"
                      label="Odkaz na Instagram"
                      onChange={handleChange}
                      isInvalid={!!errors.instagramLink}
                      feedback={errors.instagramLink}
                      id="validationFormikInstagram"
                      />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group>
                    <Form.Check
                      name="facebookLink"
                      label="Odkaz na Facebook"
                      onChange={handleChange}
                      isInvalid={!!errors.facebookLink}
                      feedback={errors.facebookLink}
                      id="validationFormikFacebook"
                      />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group>
                    <Form.Check
                      name="webLink"
                      label="Odkaz na Web"
                      onChange={handleChange}
                      isInvalid={!!errors.webLink}
                      feedback={errors.webLink}
                      id="validationFormikWeb"
                      />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                <h4 className="left">Volitelné</h4>
                <Form.Group as={Col} md="4" controlId="validationFormik09">
                    <Form.Label>Autor publikace (Mgr. Martin Jícha, Bc. Filip Jícha)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="pro defaultního uživatele nechte prázdné"
                      name="user"
                      value={values.user}
                      onChange={handleChange}
                      isInvalid={!!errors.user}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.user}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationFormik10">
                  <Form.Label>Text tlačítka na úvodní stránce</Form.Label>
                  <Form.Control
                    type="text"
                    name="button"
                    value={values.button}
                    onChange={handleChange}
                    isInvalid={!!errors.button}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.button}
                  </Form.Control.Feedback>
                </Form.Group>
                </Form.Row>
                <h4 className="left">Den brankářů</h4>
                <Form.Row>
                    <Form.Group>
                      <Form.Check
                        name="goalKeepersDay"
                        label="Zahrnout stránku Den brankářů"
                        onChange={handleChange}
                        isInvalid={!!errors.goalKeepersDay}
                        feedback={errors.goalKeepersDay}
                        id="validationFormikGKDay"
                      />
                    </Form.Group>
                </Form.Row>
                <h4 className="left">Testovací zpráva</h4>
                <Form.Row>
                  <Form.Group>
                    <Form.Check
                      name="test"
                      label="Bude uplne posledni v poradi ve zpravach"
                      onChange={handleChange}
                      isInvalid={!!errors.test}
                      feedback={errors.test}
                      id="validationFormikTest"
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <h4 className="left">ADMIN ID</h4>
                  <Form.Group as={Col} md="8" controlId="validationFormikUID">
                    <Form.Label>admin</Form.Label>
                    <Form.Control
                      type="text"
                      name="uid"
                      value={values.uid}
                      onChange={handleChange}
                      isInvalid={!!errors.uid}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.uid}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Button type="submit">Uložit zprávu</Button>
              </Form>
             )}
           </Formik>


           <ToastContainer position="top-center" className="toast-container" />

      </div>
        );
      }
   }
export default NewsForm;
