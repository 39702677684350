/**  Helper for News */
import firebase from '../../../database/firestore';


export function getPhotosFromGallery(key, photoGallery) {
      for (let i = 0; i < photoGallery.length; i++) {
        if(photoGallery[i].key === key) {
          console.log("Found photos in gallery for: " + key);
          return photoGallery[i].photos;
        }
      }

      return [];
}

export function getWelcomePhoto(key, welcomePhotos) {
      for (let i = 0; i < welcomePhotos.length; i++) {
        if(welcomePhotos[i].key === key) {
          console.log("Found welcome photo in gallery for: " + key);
          return welcomePhotos[i].photo;
        }
      }

      return undefined;
}


export function loadDocsAndRender(thisObject, limit) {
        let result = [];
        let maximumDataSize = limit !== undefined ? limit : 10000;
        const db = firebase.firestore();

        db.collection('privateDocuments')
          .orderBy('date', 'desc')
          .limit(maximumDataSize)
          .get()
          .then(querySnapshot => {
              const data = querySnapshot.docs.map(doc => doc.data());
              for (let i = 0; i < data.length; i++) {
                let title = data[i].title;
                console.log("Private document: " + title)
                result.push(data[i]);

                if(data[i].hasDocument) {
                    loadDocumentFiles(title, thisObject);
                }

                //loadWelcomePhoto(title, thisObject);

                //loadCampIntroAndRender(campsPath, data[i].photo_name, thisObject);
              }
            // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
            thisObject.setState({newsData: result, isLoading: false});
            console.log("Triggered rendering with loaded data.");
        });

  return result;
}


export function loadDocumentFiles(imageFolder, thisObject) {
      let images = [];
      const storageRef = firebase.storage().ref("private/docs/" + imageFolder);

      storageRef.listAll().then(function(result) {
          let numberOfImagesToLoad = result.items.length;
          let currentDownloadeCount = 0;
          console.log("Loaded number of private documents: #" + numberOfImagesToLoad);

          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                  images.push({src: url, documentName: imageRef.name});
                  console.log("Downloading document finished: " + url);
                  currentDownloadeCount = currentDownloadeCount + 1;
                  let allPhotosLoaded = (numberOfImagesToLoad === currentDownloadeCount);
                  if(allPhotosLoaded) {
                    thisObject.setState(state => {
                        const photoGallery = state.photoGallery.concat({key: imageFolder, photos: images});
                        return {photoGallery};
                    });

                    console.log("Finished downloading all files for: " + imageFolder);
                  }
              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });

      return images;
}
