import React from "react";
import YouTube from 'react-youtube';

import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import Header from '../../header';
import Footer from '../../footer';

import {loadTrainingVideoAndRender} from './SectionsUtil'

export default class TrainingVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoData: undefined,
      isLoading: true
    };
  }

  componentDidMount(){
      loadTrainingVideoAndRender(this);
  }

  render() {
    const {videoData, isLoading} = this.state;

    let videoIds = [];

    if(!isLoading) {
      for (let i = videoData.videoYoutubeIds.length - 1; i >= 0; i--) {
        videoIds.push(videoData.videoYoutubeIds[i]);
      }
    }

    return isLoading ? (
            <LoadingSpinner loadingState={isLoading} />
        ) : (
      <div>
    		<Header />
		        <div className="view textArticle">
                <div className="headline">
                  <h2>
                    {videoData.title}
                  </h2>
                </div>

                <div className="responsive left">
                {
                  videoIds.map((videoId, index) => (
                    <div className={"column-" + ((index % 3 ) + 1) }>
                      <YouTube
                        videoId={videoId}
                        opts={videoData.videoOptions}
                        onReady={this._onReady}
                        />
                    </div>
                  ))
                 }
                </div>

            </div>

            <Footer />

      </div>
    );
  }
}
