import React from "react";
import YouTube from 'react-youtube';

export default class PrivateDocumentDetail extends React.Component {

  render() {
    const article = this.props.article;

    const documents = this.props.documents !== undefined ? this.props.documents : undefined;

    const opts = {
     height: '450',
     width: '100%',
     playerVars: { // https://developers.google.com/youtube/player_parameters
       autoplay: 0
     }
   };

    return (
		        <div className="view gameReport privateDocs">
              <div className="headline">
                <h2>
                  {article.title}
                </h2>
              </div>


                  <p>
                     {article.description2}
                  </p>

                  {article.descriptions !== undefined ?
                  <p>
                    {article.descriptions.map((description, index) => (
                      <div>
                          <h3 className="orange">{description.headline}</h3>
                          {description.paragraphs.map((paragraph, index) => (
                            <div>
                              <p>{paragraph.link ? <a href={paragraph.text} target='_blank' rel="noopener noreferrer">{paragraph.text}</a> : paragraph.text}</p>
                            </div>
                          ))
                          }
                      </div>
                    ))
                    }
                  </p>
                  : <br/>
                  }

                  {(article.socialMediaLinks !== undefined && article.socialMediaLinks.length > 0) ?
                  <div>
                    {article.socialMediaLinks.map((link, index) => (
                      <p>
                      { link.media === 'Web' ?
                        <span> Web:  <a href='./' target='_blank'>j4k.cz</a> </span>
                          :
                        <span>{link.media} : {link.value} </span>
                     }
                     </p>
                    ))
                    }
                  </div>
                  : <br/>
                  }

                  {(article.webPageLink !== undefined && article.webPageLink.length > 0) ?
                    <p>Web: <a href={article.webPageLink} target='_blank' rel="noopener noreferrer">{article.webPageLink}</a></p>
                      : ''
                  }

                  {article.eshopLink !== undefined && article.eshopLink.length > 0 ?
                    <p>E-shop: <a href={'https:/' + article.eshopLink} target='_blank' rel="noopener noreferrer">{article.eshopLink}</a></p>
                      : ''
                  }

                  {article.hashTags !== undefined && article.hashTags.length > 0 ?
                  <p class="left">
                    {article.hashTags.map((hashTag, index) => (
                        <a href={this.getFacebookHashTagUrl(hashTag.name)} target='_blank' rel="noopener noreferrer">#{hashTag.name} </a>
                    ))
                    }
                  </p>
                  : <br/>
                  }

                  {documents !== undefined && documents.length > 0 ?
                    <div className="">
                        {documents.map((privateDoc, index) => (
                            <a href={privateDoc.src} target='_blank' rel="noopener noreferrer">{privateDoc.documentName}</a>
                            ))
                          }

                      </div>
                    : <br/>
                  }

                  {article.videoYoutubeIds !== undefined && article.videoYoutubeIds.length > 0 ?
                    <div className="responsive left">
                    {
                      article.videoYoutubeIds.map((videoId, index) => (
                        <div className={"column-" + (index + 1)}>
                          <YouTube
                            videoId={videoId}
                            opts={opts}
                            onReady={this._onReady}
                            />
                        </div>
                      ))
                     }
                    </div>
                    : <br/>
                  }

            </div>
    );
  }

  getFacebookHashTagUrl(hastTag) {
    let facebookHashTagUrl = 'https://www.facebook.com/hashtag/';
    let facebookHashTagURLParameter = '?epa=HASHTAG';

    return facebookHashTagUrl + hastTag + facebookHashTagURLParameter;
  }



}
