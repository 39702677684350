import React, { Component } from "react";

import Header from '../header';
import '../css/styles.css';

//Footer
import Footer from '../footer';



// Reference photos
import KristianCake from '../images/references/Kristián-dort-169x300.jpg';
import KristianChristmas from '../images/references/Kristián-Vánoce-167x300.jpg';


class References extends Component {
  render() {

    return (
	<div>
		<Header />

		<div class="view">

      <div class="headline">
        <h2>
          Rodiče o nás řekli
        </h2>
      </div>

      <div className="reference-quotes">
        <h4>Katarína Dvořáková-Mitanová</h4>
        <span>Děkujeme všem trenérům J4K za skvěle připravený kemp. Kluci trenovali, běhali, plavali, bruslili, skákali, užili si to maximálně.
        Už teď se těšíme na další akce, tréninky, kempy, soutěže s J4K family.
        </span>
      </div>

      <div className="reference-quotes">
        <h4>Petra Kupková</h4>
        <span>Přiznám se, nejsem fanouškem příspěvků na FCB, ale v tomhle případě musím a ráda udělám výjimku! Náš Honzík trénuje s J4K již více než rok a půl a mimo tréninky také pravidelně jezdí na brankářské tábory, které tahle skvělá parta trenérů organizuje. Už v rámci loňského zimního kempu jsem psala trenérům J4K do komentáře k jednomu jejich videu poděkování za jejich naprosto skvělou práci. Byl to videoklip z tréninků i volnočasových aktivit kluků na tomto kempu a z videa byl na první pohled vidět ten obrovský zápal všech a ta skvělá atmosféra opepřená kopou legrace, kterou tam kluci měli. Šíleně jsme se tomu s manželem smáli, jak se nám to líbilo, a za tyto bezprostřední informace jsme byli vděční (protože ten náš kluk samým zápalem ani nemá čas napsat SMS či zavolat. Tohle poděkování vám, milí trenéři, s úctou skládáme i takto veřejně, protože si ho všichni pravém zasloužíte! Chtěli bychom vám všem poděkovat za tuto vaši úžasnou a velmi profesionální práci, dá-li se to „prací“ vůbec nazvat. Je totiž vidět, že fotbalem všichni prostě žijete a že ho máte rádi. Jste obrovští srdcaři a my vám děkujeme, že své drahé zkušenosti předáváte dál těm mladším generacím, toho času nyní našim synům. Hoši, děkujeme a fandíme vám!
        </span>
      </div>

      <div className="reference-quotes">
        <h4>Michal Rous</h4>
        <span>Brankářský kemp Just4keepers se Ondrovi moc líbil a z tréninků je vždy nadšený.
        </span>
      </div>

      <div className="reference-quotes">
        <h4>Jana Panská</h4>
        <span>Moc moc děkujeme za super sestřih z kempu+ denní sestřihy na Facebooku. Za nás musím pět jenom chválu. Šimon byl na kempu poprvé a přijel úplně nadšený, hned jsme ho přihlásili na letní. Fotbal ho bavil od malička, víceméně každý sport, ale v bráně se „našel“, hrozně ho to tam baví a to je pro nás rodiče  nejdůležitější. Fotbalové tréninky ho baví samozřejmě také,zprávy o tréninku dostáváme s předstihem, maximální spokojenost.
          Ještě jednou moc díky.
        </span>
      </div>

      <div className="reference-quotes">
        <h4>Lenka Doležalová</h4>
        <span>
          Kristiánek se zúčastnil obou kempů a musím velice poděkovat . Profesionální přístup a zároveň spousty zábavy. Moc děkuji za vaše tréninky, díky nim si snad jednou Kristiánek splní svůj velký sen. Už teď se moc těší na novou sezónu a tréninky s Vámi.
        </span>

      </div>
      <div className="reference-images">
        <img src={KristianCake} alt="Christian s dortem"/>
        <img src={KristianChristmas} alt="Christian o Vánocích"/>
      </div>


    </div>

    <Footer />

	</div>
    );
  }
}

export default References;
