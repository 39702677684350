import React from "react";

import Header from '../header';
import Footer from '../footer';

import ProfileDetailComponent from './sections/about/ProfileDetailComponent';

// Profile data
import MainArticleData from '../components/data/MainArticleData'

export default class ProfileDetail extends React.Component {

  render() {
    const articleData = new MainArticleData();
    const contacts = articleData.getContacts();
    const selectedIndex = this.props.match.params.id;
    const contactProfile = contacts[selectedIndex]
    return (
	     <div>
		        <Header />
            <div class="view">
              <p>

                <ProfileDetailComponent ref={"trainer_" + selectedIndex} trainer={contactProfile} visibility= 'show' trainerIndex={selectedIndex}/>
              </p>
            </div>

            <Footer />
	     </div>
    );
  }

}
