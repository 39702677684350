import React from "react";

import '../../../css/styles.css';

import RayNewlandPhoto from '../../../images/RayNewland/ray3.jpg';
import RayNewlandQuote from '../../../images/RayNewland/ray_quote3.gif';
import RayNewlandSignature from '../../../images/RayNewland/ray_signature.gif';

export default class RayNewland extends React.Component {

  render() {

    return (
      <div className="ray-newland">

          <div className="ray-newland-quote ">
            <img src={RayNewlandPhoto} alt="Ray Newland Quote" />

            <img className = "ray-newland-quote-img" src={RayNewlandQuote} alt="Ray Newland Quote" />
          </div>

          <p>
            Jsem velmi pyšný na <b>J4K</b> a prokázané výsledky, kterých jsme dosáhli pro naše studenty od roku 1999. Pomohli jsme tisícům mladých brankářům dosáhnout jejich snu.
          </p>

          <p>
            J4K nyní působí ve více jak 30 zemích napříč 5 kontinenty s více jak 10,000 rodiči důvěřujících J4K, kteří vodí své děti na naše týdenní tréninky. Žádná jiná brankářská škola v historii se nepřibližuje výsledkům, které jsme udělali pro naše studenty. Já a všichni naši J4K trenéři žijeme naše životy pro děti všude po světě s cílem, abychom jim dali šanci, kterou jsme my v dětství nikdy neměli a pomohli jim dosáhnout jejich brankářských snů, zatímco se snažíme rodičům ušetřit peníze za naše více jak férové ceny.
          </p>
          <p>
            Těším se na to, co přinese další tisíciletí  pro J4K a jeho studenty, protože věřím, že J4K teprve začíná.
          </p>
          <p>
            Přihlaš se na brankářský trénink zdarma, protože nikdy nevíš… Třeba <b>právě ty můžeš být další J4K úspěšný příběh!</b>
          </p>
          <p>
            <b>Just4keepers – Mění brankářské životy k lepšímu od roku 1999!</b>
          </p>
          <p>
            Zakladatel Just4keepers Ray Newland
          </p>

          <img className="ray-newland-quote" src={RayNewlandSignature} alt="Ray_signature" />

          <p>
            <a href='http://just4keepers.co.uk/about-j4k/message-from-founder/'>Číst více od zakladatele Just4Keepers – Ray Newland</a>
          </p>
      </div>
    );
  }

}
