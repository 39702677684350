import React from 'react';
import firebase from '../../../../database/firestore';

// Forms
import {Formik} from 'formik';
import {Form, Button, Col} from 'react-bootstrap';
import "../../../../css/bootstrap.scss";
// Form validation
import * as yup from 'yup';

// Notification pop up
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const schema = yup.object({
  password: yup.string().required('Heslo je povinné.')
});

const privateDocsPath = "private/docs/logins";

const writeUid = "56128530574-jlsm26805s3dqeug21gp38cbvjffs8rf.apps.googleusercontent.com";
const targetPwd = 'J4Krodina';

const errorNotificationOptions = {
  autoClose: true,
  type: toast.TYPE.ERROR
};

class LoginForm extends React.Component {

  constructor() {
   super();
   this.applicantForm = React.createRef();

   this.state = {
     loggedIn: false,
   }

 }

 componentDidMount(){
   this.state = {
     privateDocsPage: this.props.targetPage
   }
 }


  checkPassword(values) {
    const db = firebase.firestore();
    db.settings({
      //timestampsInSnapshots: true // without this, we get a warning in the console, which tells us the timestamps stored in Firestore will be read back as timestamp objects, as opposed to system date objects.
    });

    let enteredPassword = values.password;

    if(targetPwd !== enteredPassword) {
         console.log("Entered wrong password.");
         toast.error("Nelze se přihlásit, zadejte prosím správné heslo.", errorNotificationOptions);
    } else {
      let docPath =  new Date().toString();
      db.collection(privateDocsPath)
      .doc(docPath) // unique document path
      .set({
        loggedDate: new Date(),
        uid: writeUid
      })
      .then(() => {
        console.log("Sucessfull logged to private/docs section");
        let notificationOptions = {
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        };

        this.state.privateDocsPage.setState({isLoggedIn: true});

        toast.success("Správné ověřovací údaje", notificationOptions);
      }).catch(error => {

        toast.error("Nelze se přihlásit, zadejte prosím správné heslo.", errorNotificationOptions);
        console.log(error);
      });
    }

  };

  render() {
    return (
      <div id='applicationForm' className="use-bootstrap">

        <Formik
             validationSchema={schema}
             onSubmit={ values => this.checkPassword(values) }
             initialValues={{
               password: ''
             }}
           >
             {({
               handleSubmit,
               handleChange,
               handleBlur,
               values,
               touched,
               isValid,
               errors
             }) => (
               <Form noValidate onSubmit={handleSubmit}
                                ref={this.applicantForm}>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="password">
                     <Form.Label>Heslo</Form.Label>
                      <Form.Control
                         type="password"
                         placeholder="heslo"
                         name="password"
                         value={values.title}
                         onChange={handleChange}
                         isInvalid={!!errors.title}
                         isValid={errors.title}
                      />

                      <Form.Control.Feedback type="invalid">
                         {errors.password}
                      </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                <Button type="submit">Přejít do soukromé sekce</Button>
              </Form>
             )}
           </Formik>


           <ToastContainer position="top-center" className="toast-container" />

      </div>
        );
      }
   }
export default LoginForm;
