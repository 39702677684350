import React, { Component } from 'react';
import DotLoader from "react-spinners/DotLoader";

const loaderCss = {
  display: 'block',
  margin: '0 auto',
  color: '#000000'
};

export default class LoadingSpinner extends Component {

  render () {
    const isLoading = this.props.loadingState;

    return (
      <div className="sweet-loading center">
        <DotLoader
          css={loaderCss}
          size={60}
          color={"#e67817"}
          loading={isLoading}
        />
        <div className="orange center">
          Načítám data...
        </div>
     </div>
    )
  }
}
