  // Profile images
import martinJichaJr from '../../images/profiles/martin_jicha_jr.jpg';
import martinJicha from '../../images/profiles/martin_jicha.jpg';
import filipJicha from '../../images/profiles/filip_jicha.jpg';
import tomasJicha from '../../images/profiles/tomas_jicha.jpg';

import profileMartinJichaJr from '../../images/profiles/Profile-photo-Martin-Jr.jpg';
import profileMartinJicha from '../../images/profiles/Profile-photo-Martin.jpg';
import profileFilipJicha from '../../images/profiles/Profile-photo-Filip.jpg';
import profileTomasJicha from '../../images/profiles/Profile-photo-Tomas.jpg';

import emailIcon from '../../images/profiles/email.png';
import bankIcon from '../../images/profiles/bank.PNG';

// Slider images
import Camps from '../../images/sliders/Camps.png';
import GolmanGloves from '../../images/sliders/Gloves.jpg';
import GolmanOpportunity from '../../images/sliders/Training_oportunity.jpg';
import GolmanSchool from '../../images/sliders/slides_0.JPG';

// Try Training images
import tryTrainingImage from '../../images/all_panoramatic.jpg';
import aboutUsImage from '../../images/about/about.jpg';

// Family member image navigations:
import ballGoal from '../../images/icons/ball_goal.jpg';
import ball from '../../images/icons/ball.jpg';
import gloves from '../../images/icons/gloves.jpg';
import education from '../../images/icons/education.jpg';

// Bottom image navigations:
import news from '../../images/bottomNavigations/novinky.jpg';
import eshop from '../../images/bottomNavigations/eshop.png';
import training from '../../images/bottomNavigations/brankarsky_trenink.jpg';
import camps from '../../images/bottomNavigations/kempy_akce.jpg';
import coaches from '../../images/bottomNavigations/treneri.jpg';
import references from '../../images/bottomNavigations/reference.jpg';
import blog from '../../images/bottomNavigations/blog.jpg';
import trainingOpportunity from '../../images/bottomNavigations/trenerska_prilezitost.jpg';


const articleDetails = [
  {
     title: 'Brankářská škola Just4keepers CZ',
     sliderDescription: '',
     sliderImage: GolmanSchool,
     user: 'Martin Jícha',
     userProfile: martinJichaJr,
     description: 'Máte doma nadšeného brankáře/ku? Přidejte se k rodině Just4keepers. Každý týden přijedeme do určené lokality, kde proběhne hodinový specializovaný brankářský trénink pod licencovanými trenéry. Nabízíme možnost skupinových i individuálních tréninků. Především zaručujeme pořádný přísun zábavy a chytání s vždy dobře naladěnými trenéry! ',
     button: 'Číst dále',
     trainingOffers: true
   },
   {
      title: 'Brankářské kempy',
      sliderDescription: 'Přijď a vyzkoušej J4K letní nebo zimní kempy a zjisti proč je navštěvují brankáři ve více jak 35 zemích světa.',
      sliderImage: Camps,
      user: 'Mgr. Martin Jícha',
      userProfile: martinJicha,
      button: 'Číst dále',
      trainingCamps: true,

      videoYoutubeId: 'pj4hfVcV-qY',
      videoYoutubeId2: 'uunhIBPrud4',
      videoYoutubeId3: 'cr6O-I4b6B4'
    },
    {
       title: 'Brankářské rukavice',
       sliderDescription: 'Stále jdeme vpřed, nový design, nové materiály, bezkonkurenční ceny proti komerčním značkám.',
       sliderImage: GolmanGloves,
       user: 'Tomáš Jícha',
       userProfile: tomasJicha,
       button: 'Číst dále',
       trainingGloves: true
     },
     {
        title: 'Trenérská příležitost',
        sliderDescription: 'Hledáme trenéry brankářů ve všech regionech České republiky, kteří by se rádi přidali k naší brankářské rodině.',
        sliderImage: GolmanOpportunity,
        user: 'Filip Jícha',
        userProfile: filipJicha,
        button: 'Číst dále',
        trainingOpportunity: true
      },
]

const getFamilyMemberNavigations = [
  {
     title: 'Brankářské tréninky',
     image: ball,
     target: 'slider-report/0'
   },
   {
      title: 'Profi trenéři',
      image: education,
      target: '/about'
    },
    {
       title: 'Vlastní značka',
       image: gloves,
       target: '/eshop'
    },
    {
       title: 'Kempy & akce',
       image: ballGoal,
       target: 'brankarskekempy/letni'
    },
]

const bottomImageNavigations = [
  {
     title: 'Novinky',
     image: news,
     target: 'news'
   },
   {
      title: 'E-shop',
      image: eshop,
      target: '/eshop'
   },
   {
      title: 'Brankářské tréninky',
      image: training,
      target: 'slider-report/0'
    },
    {
       title: 'Kempy a akce',
       image: camps,
       target: 'brankarskekempy/letni'
    },
    {
       title: 'Trenéři',
       image: coaches,
       target: 'coaches'
     },
     {
        title: 'Reference',
        image: references,
        target: 'references'
     },
     {
        title: 'Blog - život goalkeepera',
        image: blog,
        target: 'blogs'
      },
      {
         title: 'Trenérská příležitost',
         image: trainingOpportunity,
         target: 'slider-report/3'
      },
]

const contacts = [
  {
     id: 'Mgr. Martin Jícha',
     name: 'Martin Jícha',
     degree: 'Mgr. ',
     number: 1,
     age: 50,
     foto: martinJicha,
     profilePhoto: profileMartinJicha,
     phone: '731 575 400',
     youngthCareer: [],
     playerCareer: ["Modeta Jihlava", "Dynamo České Budějovice", "Luka nad Jihlavou", "TJ Dálnice Speřice", "FSC Stará Říše", "TJ Velký Beranov"],
     trainerCareer: ["Výchova tří synů brankářů", "Mládežnické týmy FC PSJ Jihlava", "TJ Sokol Bedřichov", "SFK Vrchovina", "Brankářská škola Just4keepers"],
     education: ["Magisterský program Masarykovy University, Fakulta tělesné výchovy a sportu s pedagogikou"],
     stages: ["Fotbalová stáž: Manchester United 2022"],
     thesis: "Diplomová práce na téma: Sledování a hodnocení brankářů na MS 1994",
     sliderInfos: ["Kariéra: Dynamo České Budějovice", "Fotbalová stáž: Manchester United", "Vzdělání: Mgr titul z MU, Fakulta tělesné výchovy a sportu s pedagogikou", "Brankářská škola Just4keepers"]
   },
   {
      id: 'Martin Jícha',
      name: 'Martin Jícha',
      number: 2,
      age: 28,
      foto: martinJichaJr,
      profilePhoto: profileMartinJichaJr,
      phone: '736 515 979',
      currentPosition: 'Současný klub: FSC Stará Říše',
      youngthCareer: ["FC Vysočina Jihlava", "TJ Sokol Bedřichov", "SFK Vrchovina"],
      playerCareer: ["Slavoj Vyšehrad", "FC Graffin Vlašim", "U.B. Conquense (Segunda B - Španělsko)", "SC Requena (Tercera division - Španělsko)", "Ceahlaul Piatra Neamt (1.liga - Rumunsko)", "SK Viktorie Jirny", "FK Chmel Blšany", "FSC Stará Říše (současnost)"],
      trainerCareer: ["Brankářská škola Just4keepers", "Regionální fotbalová akademie Kraje Vysočina (současnost)"],
      education: ["Střední pedagogická škola - specializace tělovýchova a sport"],
      stages: ["Fotbalová stáž - Manchester United 2022, AFC Ajax"],
      licence: "Trenérská licence - Trenér brankářů A licence FAČR",
      thesis: "",
      sliderInfos: ['Současný klub: FSC Stará Říše', "Fotbalová stáž - Manchester United", "Trenérská licence - Trenér brankářů A licence FAČR", "Trenér brankářů Regionální fotbalové Akademie Kraje Vysočina"]
    },
    {
       id: 'Mgr. Tomáš Jícha',
       name: 'Tomáš Jícha',
       degree: 'Mgr. ',
       number: 3,
       age: 25,
       foto: tomasJicha,
       profilePhoto: profileTomasJicha,
       phone: '734 173 342',
       currentPosition: 'Současný klub: FC ŽDAS Ždár nad Sázavou',
       youngthCareer: ["FC Vysočina Jihlava", "SFK Vrchovina"],
       playerCareer: ["FC Velké Meziříčí", "FK Hodonín", "SC Laa", "SV Furth (Rakousko)", "AFK Tišnov", "FC ŽDAS Ždár nad Sázavou"],
       trainerCareer: ["AFC Humpolec - starší dorost", "Sparta Brno - minipřípravka", "Brankářská škola Just4keepers"],
       education: ["Magisterský program Masarykovy University - zaměření kondiční trenér, Fakulta sportovních studií"],
       stages: ["Fotbalová stáž: Manchester United 2022, FC Brighton (Premier League)"],
       licence: "Trenérská licence - Trenér brankářů A licence FAČR",
       //thesis: "Bakalářská práce na téma: Vliv intervenčního programu na motorické schopnosti fotbalových brankářů mladšího a staršího školního věku.",
       thesis: "Diplomová práce - Rozvoj vybraných motorických schopností fotbalových brankářů",
       sliderInfos: ['Současný klub: FC ŽDAS Ždár nad Sázavou', "Fotbalová stáž: Manchester United", "Trenérská licence - Trenér brankářů A licence FAČR", "Vzdělání: Mgr. titul z FSS MU"]
     },
     {
        id: 'Mgr. Filip Jícha',
        name: 'Filip Jícha',
        degree: 'Mgr. ',
        number: 4,
        age: 23,
        foto: filipJicha,
        profilePhoto: profileFilipJicha,
        phone: '734 171 793',
        currentPosition: 'Současný klub: MFK Vyškov',
        youngthCareer: ["FC Vysočina Jihlava", "AFC Humpolec", "FC Slovan Havlíčkův Brod"],
        playerCareer: ["FC Žďas Žďár nad Sázavou", "1.SK Prostějov", "SK Hanácká Slávia Kroměříž", " 1.SC Znojmo FK", "ČSK Uherský Brod", "MFK Vyškov"],
        trainerCareer: ["Brankářská škola Just4keepers"],
        education: ["Magisterský program - Učitelství Tělesné výchovy a Občanské výchovy", "Balakářský program Masarykovy University, Fakulta sportovních studií a sportu s Pedagogickou fakultou"],
        stages: ["Fotbalová stáž: Manchester United 2022"],
      //  thesis: "Bakalářská práce na téma: Tréninková příprava brankáře ve fotbale v různých věkových kategorií",
        thesis: "Diplomová práce - Vybrané parametry hry nohou fotbalového brankáře",
        sliderInfos: ['Současný klub: MFK Vyškov', "Fotbalová stáž: Manchester United", "Vzdělání: Mgr. titul z FSS Masarykovy University", "Brankářská škola Just4keepers"]
      },
];

const emailContact =    {
     email: 'just4keeperscz@gmail.com',
     icon: emailIcon
   }

   const bankAccounts =    [
        {
          purpose: 'Brankářské kempy, brankářské tréninky',
          accountNr: '90949622/5500',
          icon: bankIcon,
          isForCamps: true
        },
        {
          purpose: 'Nákup rukavic apod.',
          accountNr: '115-4091450207/0100',
          icon: bankIcon,
          isForCamps: false
        }
   ]

class MainArticleData {

	getArticleDetails() {
		return articleDetails;
	}

  getBottomImageNavigations() {
    return bottomImageNavigations;
  }

  getFamilyMemberNavigations() {
    return getFamilyMemberNavigations;
  }

  getTryTrainingImage() {
    return tryTrainingImage;
  }

  getAboutUsImage() {
    return aboutUsImage;
  }


  getContacts() {
    return contacts;
  }

  getContact(id) {
    return contacts.filter(a => a.id === id)[0];
  }

  getEmailContact() {
    return emailContact;
  }

  getBankAccounts() {
    return bankAccounts;
  }

  getBankAccountNrForCamps() {
    return bankAccounts.filter(a => a.isForCamps)[0].accountNr;
  }

  getFacebookHashTagUrl(hastTag) {
    let facebookHashTagUrl = 'https://www.facebook.com/hashtag/';
    let facebookHashTagURLParameter = '?epa=HASHTAG';

    return facebookHashTagUrl + hastTag + facebookHashTagURLParameter;
  }
}

export default MainArticleData;
