/**  Helper for camps */
import React from "react";
import firebase from '../../database/firestore';

import PhotoGallery from '../../components/PhotoGallery';

const regards = "Zdraví";
const regardsFamily = "Rodina";
const regardsSignature = "Just4keepers CZ";

export function getSignature() {
  return (
    <p>
      {regards}
      <br/>
      {regardsFamily} <b>{regardsSignature}</b>
    </p>
  )
}


export function getCampIntroduction(intro, imageUrl, promoPhotos) {
    return (
      <div>
      <div className="headline">
        <h1>
          {intro.title}
        </h1>
      </div>

        <div className="indent">
          {intro.important_note !== undefined ? <p><b>{intro.important_note}</b></p> : ''}

          {imageUrl !== undefined ? <img src={imageUrl} alt="J4K" /> : ''}

          {
            intro.intro_paragraphs !== undefined ?
              intro.intro_paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
          ))
          : ''
          }

          <p><b>{intro.intro_important}</b></p>
        </div>

        <div className="responsive left">
          {
            promoPhotos !== undefined ?
              promoPhotos.map((promoUrl, index) => (
                <div className={"column-" + (index + 1)}>
                  <img src={promoUrl} alt="Brankářé kempy"/>
                </div>
              ))
               : ''
          }
        </div>
      </div>
    )
}

export function getCampArticles(campArticles, photoGallery) {
    return (
      <div>
        {campArticles.map((item, index) => (
          index === 0 ? '' :
          <div key={index} className="campArticle">
            <div className="campArticleHeader">
              <h2>{item.event_place}</h2>
              <p>
                <b>{item.article_dates}</b>
              </p>
              <p>
                <b>Cena: {item.article_prices}</b>
              </p>
              {item.article_paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}

              {getSignature()}
            </div>

            <PhotoGallery photos={getPhotosFromGallery(item.event_place, photoGallery)}/>
          </div>
      ))}
      </div>
    )
}

export function getPhotosFromGallery(event_place, photoGallery) {
      for (let i = 0; i < photoGallery.length; i++) {
        if(photoGallery[i].key === event_place) {
          console.log("Found photos in gallery for: " + event_place);
          return photoGallery[i].photos;
        }
      }

      return [];
}

export function createCampDropDownOptions(campsPath) {
    let options = [];
    const db = firebase.firestore();

    db.collection(campsPath)
      .where('article_order', '>=', 0)
      .orderBy('article_order', 'asc')
      .get()
      .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          for (let i = 1; i < data.length; i++) {
            let eventPlace = data[i].event_place; // Třešť / Libice nad Doubravou
            let eventPlaceShort = data[i].event_place_short; // Třešť / Libice n. D.
            let availableCampsOnPlace = data[i].available_camps;
            availableCampsOnPlace.map(availableCamp => (
              db.collection(campsPath)
              .doc(eventPlace) // Libice nad Doubravou
              .collection(availableCamp) // 13.7. - 19.7. 2020
              .get()
              .then(querySnapshot => {
                const camps = querySnapshot.docs.map(doc => doc.data());
                for (let j = 0; j < camps.length; j++) {
                  let eventDateLabel = camps[j].start_date + (camps[j].end_date !== undefined ? "- " + camps[j].end_date : '');
                  let eventId = eventPlace + "_" + eventDateLabel;
                  let yearCondition = camps[j].year_condition !== undefined ? camps[j].year_condition + ', ' : '';
                  let bonus = camps[j].bonus ? ( " " + camps[j].bonus) : '';
                  let eventLabel = eventDateLabel + " " +  eventPlaceShort + " (" + yearCondition + camps[j].price + bonus + ")";
                  options.push({name: eventLabel, id: eventId });
                  if(camps.length === (j + 1)) {
                    console.log("Available camps loadeding finished!");
                  }
                }
              })
             )
            )
          }
    });

    return options;
}


export function loadCampMatchCategoryOptionsAndRender(thisObject, campsPath) {
  let options = [];
  const db = firebase.firestore();

  db.collection(campsPath)
    .where('article_order', '>=', 0)
    .orderBy('article_order', 'asc')
    .get()
    .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        for (let i = 1; i < data.length; i++) {
          let minCategory = Number(data[i].min_match_category);
          options.push(minCategory + " a mladší");
          let category = Number(minCategory);
          for (let i = 1; i <= 3; i++) {
            options.push(--category + " - " + --category);
          }
          options.push(--category + " a starší");
          // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
          thisObject.setState({matchCategories: options, isLoading: false});
        }
  });

  return options;
}

export function loadCampPriceAndRender(thisObject, campsPath) {
  const db = firebase.firestore();

  db.collection(campsPath)
    .where('article_order', '>=', 0)
    .orderBy('article_order', 'asc')
    .get()
    .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        for (let i = 1; i < data.length; i++) {
          let priceNotification = data[i].priceNotification;
          // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
          thisObject.setState({campPrice: priceNotification});
        }
  });
}

export function loadCampsDataAndRender(campsPath, thisObject) {
        let result = [];
        const db = firebase.firestore();

        db.collection(campsPath)
          .where('article_order', '>=', 0)
          .orderBy('article_order', 'asc')
          .get()
          .then(querySnapshot => {
              const data = querySnapshot.docs.map(doc => doc.data());
              for (let i = 0; i < data.length; i++) {
                result.push(data[i]);
                if(i === 0) {
                  loadCampIntroAndRender(campsPath, data[i].photo_name, thisObject);
                  loadPromoImages(campsPath, thisObject);
                } else {
                  let eventPlace = data[i].event_place;
                  loadPhotoGalleryImages(campsPath, eventPlace, thisObject);
                }
              }
            // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
            thisObject.setState({campsData: result, isLoading: false});
            console.log("Triggered rendering with loaded data.");
        });

  return result;
}

export function loadCampIntroAndRender(campsPath, fileName, thisObject) {
  if(fileName !== undefined) {
    console.log("Downloading image.." + fileName);
    const storage = firebase.storage().ref(campsPath + "/intro");

    storage.child(`${fileName}`).getDownloadURL().then((url) => {
      thisObject.setState({photoUrl: url, isIntroLoading: false});
      console.log("Image Downloaded:" + fileName);
    }).catch((error) => {
      console.log(error);
    })
  } else {
      console.log("No Image for downloading needed.");
      thisObject.setState({isIntroLoading: false});
  }
}

export function loadPhotoGalleryImages(imageFolder, eventPlace, thisObject) {
      let images = [];
      const storageRef = firebase.storage().ref(imageFolder + "/" + eventPlace);

      storageRef.listAll().then(function(result) {
          let numberOfImagesToLoad = result.items.length;
          let currentDownloadeCount = 0;
          console.log("Loaded number of photos: #" + numberOfImagesToLoad);
          if(numberOfImagesToLoad === 0) {
            thisObject.setState({photoGallery: []});
          }
          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                  images.push({src: url, thumbnail: url, thumbnailWidth: 320,  thumbnailHeight: 230});
                  console.log("Downloading finished: " + url);
                  currentDownloadeCount = currentDownloadeCount + 1;
                  let allPhotosLoaded = (numberOfImagesToLoad === currentDownloadeCount);
                  if(allPhotosLoaded) {
                    thisObject.setState(state => {
                        const photoGallery = state.photoGallery.concat({key: eventPlace, photos: images});
                        return {photoGallery};
                    });

                    console.log("Finished downloading all files for: " + eventPlace);
                  }
              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });

      return images;
}

export function loadPromoImages(imageFolder, thisObject) {
      let images = [];
      const storageRef = firebase.storage().ref(imageFolder + "/intro/promo");

      storageRef.listAll().then(function(result) {
          let numberOfImagesToLoad = result.items.length;
          let currentDownloadeCount = 0;
          console.log("Loaded number of promo photos: #" + numberOfImagesToLoad);
          if(numberOfImagesToLoad === 0) {
            thisObject.setState({promoPhotos: []});
          }
          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                  images.push(url);
                  console.log("Downloading promo photo finished: " + url);
                  currentDownloadeCount = currentDownloadeCount + 1;
                  let allPhotosLoaded = (numberOfImagesToLoad === currentDownloadeCount);
                  if(allPhotosLoaded) {
                    thisObject.setState({promoPhotos: images});
                    console.log("Finished downloading all promo files for: " + imageFolder);
                  }
              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });

      return images;
}
