import React from "react";

import Header from '../../../header';
import Footer from '../../../footer';
import LoginPage from './LoginPage';
import PrivateDocuments from './PrivateDocuments';

export default class PrivateDocsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    };
  }

  render() {
    const {isLoggedIn} = this.state;

    if(!isLoggedIn) {
      return ( <div>
          <Header />
          <LoginPage targetPage={this} />
          <Footer />
        </div>
      );
    } else
      return (<PrivateDocuments />);
  }

}
