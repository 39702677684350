import React from "react";

import '../css/styles.css';

export default class TrainingOpportunity extends React.Component {


  render() {
    const article = this.props.article;

    return (
		        <div class="view textArticle">
                <div class="headline">
                  <h2>
                    {article.title}
                  </h2>

                  <h4>
                    Hledáme další trenéry – staň se součástí naší brankářské J4K rodiny!
                  </h4>
                </div>

                <p>
                    <img src={article.sliderImage} alt="Trenérská příležitost"/>

                     {article.description}
                     <p>
                      Přidej se k jedné z největších brankářských škol na světě s 20ti letou tradicí ve více jak 30 zemích světa. V České republice působíme od roku 2016 a hledáme další trenérské kolegy do týmu, kteří rádi pracují s dětmi a …
                     </p>

                     <p>
                      Rádi k nám do týmu přijmeme trenéry, kteří by nám vypomáhali s tréninky v již námi vedených lokalitách (Kraj Vysočina, Brno a okolí), trenéry co by si chtěli vést vlastní lokalitu či si vzali na starost vedení brankářské školy v celém kraji. Organizujeme i spoustu brankářský kempů a různých akcí.
                     </p>

                     <p>
                      Díky Just4keepers mají možnost brankářských tréninků i děti z menších klubů – dej těmto brankářům možnost dobrého, zábavnéhotréninku i ty v tvé lokalitě a dělej práci, která tě bude bavit!
                     </p>

                     <p class="left">
                     <ul class="triangle-li">
                        <li>Věříme, že každému trenérovi, který se k nám připojí jsme schopni pomoci s dosažením jeho trenérských a finančních cílů.</li>
                        <li>Naše plná podpora při začátku provést vás krok po kroku našim dlouhodobě ověřeným systémem.</li>
                        <li>Naše plná podpora při začátku provést vás krok po kroku našim dlouhodobě ověřeným systémem.</li>
                        <li>Žádný vstupní poplatek.</li>
                        <li>Krása na J4K je ta, že je to převážně vaše vlastní podnikání (vedení vlastních lokalit). Budeš svým vlastním šéfem, ale budeš mít za sebou mezinárodní tým trenérů, kteří již úspěšně vedou své J4K brankářské školy.</li>
                        <li>J4K vlastní značka brankářských rukavic a dalšího vybavení.</li>
                        <li>Neváhej nás s dalšími dotazy kontaktovat na <b>just4keeperscz@gmail.com</b> , tel. <b>731 575 400</b> a v případě zájmu se poté domluvíme na osobní schůzce.</li>
                    </ul>
                     </p>

                     <p class="speech left">
                        <h5>
                          Nenech si ujít tuhle skvělou životní příležitost!
                        </h5>

                        <p>
                          Jednou ve vašem životě vám bude nabídnuta jedinečná a opravdová příležitost, která může změnit život vám a vaší rodině k lepšímu. A přesně tohle je ta příležitost!
                        </p>

                        <p>
                          Just4keepers brankářská škola změnila můj život (a dalším J4K kolegům) na úroveň, o které jsem nikdy ani nesnil a to samé můžeme dokázat i pro vás! Tak pojďte a udělejte si fantastický život pro sebe a svou rodinu s opravdovými přáteli v rámci organizace J4K. Jsme připraveni vás přivítat jako kamaráda do našeho mezinárodního týmu a mít vysněnou trenérskou práci!
                        </p>

                        <p>
                            <b>Ray Newland</b> – zakladatel Just4keepers mezinárodní brankářské školy
                        </p>

                     </p>
                     <p>
                        Budeme se těšit. Tým <b>Just4keepers CZ</b>
                    </p>
                </p>

            </div>
    );
  }
}
