import React from "react";

import '../../../css/styles.css';

import {NavLink} from "react-router-dom";

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../../../css/slider-animations.css';

// Trainers info
import MainArticleData from '../../../components/data/MainArticleData';

import ProfileDetailComponent from './ProfileDetailComponent';


export default class AboutUs extends React.Component {


  renderActiveProfile(event) {
    const contactsData = new MainArticleData();
    const contacts = contactsData.getContacts();
    var currentIndex = event.slideIndex;

    // hide all profiles
    {contacts.map((item, index) => (
        document.getElementById("trainer_" + index).setAttribute("class", "hide")
    ))}

    // show selected profile
    document.getElementById("trainer_" + currentIndex).setAttribute("class", "show");
  }

  render() {
    const contactsData = new MainArticleData();
    const contacts = contactsData.getContacts();
    return (
      <div className="about-us">

        <h3>
        Kdo jsme
        </h3>

          <p>
          Rodina brankářů, nadšenců do chytání, fotbalu, všech možných sportů, trénování a všeobecně výchovy dětí. V České republice zastupujeme největší mezinárodní brankářskou školu <b>Just4keepers</b>.
          </p>

          <p>
          Vše děláme s nasazením, neustále se rozvíjíme a hlavně nás to strašně baví!.
          </p>

          <Slider touchDisabled={true} className="slider-wrapper profile-slider" onSlideChange={event =>
                this.renderActiveProfile(event)
            }>
            {contacts.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.profilePhoto}') no-repeat center center` }}
              >
                <div className="inner">

                  <h1>{item.name}</h1>
                  <div className="slider-carier-info">
                    {item.sliderInfos.map( (info, index) => (
                       <div key={index}>{info}</div>
                    ))
                  }
                  </div>

                    <button id="btn-profile-read-more">
                      <NavLink to= {"profile/" + index}>
                          Číst více
                      </NavLink>
                    </button>

                </div>

              </div>
            ))}
          </Slider>

          <div ref="profileDetail" id="profile-detail">
            {contacts.map((item, index) => (
                <ProfileDetailComponent ref={"trainer_" +index} trainer={item} visibility= {(index === 0) ? 'show' : 'hide'} trainerIndex={index} key={index}/>
              ))}
          </div>

      </div>
    );
  }

}
