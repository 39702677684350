/**  Helper for News */
import firebase from '../../database/firestore';


export function getPhotosFromGallery(key, photoGallery) {
      for (let i = 0; i < photoGallery.length; i++) {
        if(photoGallery[i].key === key) {
          console.log("Found photos in gallery for: " + key);
          return photoGallery[i].photos;
        }
      }

      return [];
}

export function getWelcomePhoto(key, welcomePhotos) {
      for (let i = 0; i < welcomePhotos.length; i++) {
        if(welcomePhotos[i].key === key) {
          console.log("Found welcome photo in gallery for: " + key);
          return welcomePhotos[i].photo;
        }
      }

      return undefined;
}


export function loadNewsAndRender(thisObject, limit) {
        let result = [];
        let maximumDataSize = limit !== undefined ? limit : 10000;
        const db = firebase.firestore();

        db.collection('news')
          .orderBy('date', 'desc')
          .limit(maximumDataSize)
          .get()
          .then(querySnapshot => {
              const data = querySnapshot.docs.map(doc => doc.data());
              for (let i = 0; i < data.length; i++) {
                let title = data[i].title;
                console.log("News: " + title)
                result.push(data[i]);
                if(data[i].hasGallery) {
                  if(data[i].hasLargeGallery) {
                    loadFirst10PhotoGalleryImages(title, thisObject);
                  }
                  loadPhotoGalleryImages(title, thisObject);
                }

                loadWelcomePhoto(title, thisObject);

                //loadCampIntroAndRender(campsPath, data[i].photo_name, thisObject);
              }
            // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
            thisObject.setState({newsData: result, isLoading: false});
            console.log("Triggered rendering with loaded data.");
        });

  return result;
}


export function loadNewsByTitleAndRender(articleTitle, thisObject) {
        let result = [];
        const db = firebase.firestore();

        db.collection('news')
          .where('title', '==', articleTitle)
          .get()
          .then(querySnapshot => {
              const data = querySnapshot.docs.map(doc => doc.data());
              for (let i = 0; i < data.length; i++) {
                let title = data[i].title;
                console.log("News: " + title)
                result.push(data[i]);
                if(data[i].hasGallery) {
                  loadPhotoGalleryImages(title, thisObject);
                }

                loadWelcomePhoto(title, thisObject);
              }
            // !IMPORTANT!  When setting state the render method will be called, thus rerendering the UI
            thisObject.setState({newsData: result, isLoading: false});
            console.log("Triggered rendering with loaded data.");
        });

  return result;
}

export function loadWelcomePhoto(imageFolder, thisObject) {
      const storageRef = firebase.storage().ref("news/" + imageFolder + "/welcome");

      storageRef.listAll().then(function(result) {
          if(result.items.length === 0) {
            thisObject.setState(state => {
                const welcomePhotos = state.welcomePhotos.concat({key: imageFolder, photo: undefined});
                return {welcomePhotos};
            });
          }
          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                    thisObject.setState(state => {
                        const welcomePhotos = state.welcomePhotos.concat({key: imageFolder, photo: url});
                        return {welcomePhotos};
                    });
                    console.log("Finished downloading welcome photo for: " + imageFolder);

              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });
}

export function loadPhotoGalleryImages(imageFolder, thisObject) {
      let images = [];
      const storageRef = firebase.storage().ref("news/" + imageFolder);

      storageRef.listAll().then(function(result) {
          let numberOfImagesToLoad = result.items.length;
          let currentDownloadeCount = 0;
          console.log("Loaded number of photos: #" + numberOfImagesToLoad);

          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                  images.push({src: url, thumbnail: url, thumbnailWidth: 320,  thumbnailHeight: 230});
                  console.log("Downloading finished: " + url);
                  currentDownloadeCount = currentDownloadeCount + 1;
                  let allPhotosLoaded = (numberOfImagesToLoad === currentDownloadeCount);
                  if(allPhotosLoaded) {
                    thisObject.setState(state => {
                        const photoGallery = state.photoGallery.concat({key: imageFolder, photos: images});
                        return {photoGallery};
                    });

                    console.log("Finished downloading all files for: " + imageFolder);
                  }
              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });

      return images;
}

export function loadFirst10PhotoGalleryImages(imageFolder, thisObject) {
      let images = [];
      const storageRef = firebase.storage().ref("news/" + imageFolder);

      storageRef.list({ maxResults: 10}).then(function(result) {
          let numberOfImagesToLoad = result.items.length;
          let currentDownloadeCount = 0;
          console.log("Loaded number of photos: #" + numberOfImagesToLoad);

          result.items.forEach(imageRef => {
                imageRef.getDownloadURL().then(url => {
                  images.push({src: url, thumbnail: url, thumbnailWidth: 320,  thumbnailHeight: 230});
                  console.log("Downloading finished: " + url);
                  currentDownloadeCount = currentDownloadeCount + 1;
                  let allPhotosLoaded = (numberOfImagesToLoad === currentDownloadeCount);
                  if(allPhotosLoaded) {
                    thisObject.setState(state => {
                        const photoGallery = state.photoGallery.concat({key: imageFolder, photos: images});
                        return {photoGallery};
                    });

                    console.log("Finished downloading all files for: " + imageFolder);
                  }
              }).catch(function(error) {
                console.log(error);
              })
          }
        );
      }).catch(function(error) {
          console.log(error);
      });

      return images;
}
