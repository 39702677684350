import React from "react";

import LoginForm from '../../../components/forms/private/docs/LoginForm';

export default class LoginPage extends React.Component {

  render() {
    const docsPage = this.props.targetPage;

    return (
      <div>

           <div className="view textArticle camps">
              <h1>Pro členy</h1>

              <LoginForm targetPage={docsPage}/>
           </div>


      </div>
    )
  }

}
