import React from "react";

import LoadingSpinner from '../../../components/spinner/LoadingSpinner';

import Header from '../../../header';
import Footer from '../../../footer';
import PrivateDocumentDetail from './PrivateDocumentDetail';
import LoginPage from './LoginPage';

import {loadDocsAndRender, getWelcomePhoto, getPhotosFromGallery} from './PrivateDocsUtil'

export default class PrivateDocuments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
      photoGallery: [],
      isLoading: true
    };
  }

  componentDidMount(){
      loadDocsAndRender(this);
  }

  render() {
    const { newsData, isLoading, photoGallery} = this.state;

    let isGalleriesLoading = true;
    let numberOfDocumentsToLoad = 0;
    // dynamic loading of news from database
    if(!isLoading) {
      newsData.filter(data => data.hasDocument).map(persistedNews => (
        numberOfDocumentsToLoad = numberOfDocumentsToLoad + 1
      ));

      isGalleriesLoading = numberOfDocumentsToLoad < photoGallery.length;
    }

    let loading = isLoading || isGalleriesLoading;
    console.log(" isLoading= " + isLoading + ". isGalleriesLoading= " + isGalleriesLoading);

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
            <Header />

            <div className="news">
                {newsData.map((article, index) => (
                    <div >
                      <PrivateDocumentDetail article={article} documents={getPhotosFromGallery(article.title, photoGallery)} />

                    </div>
                ))
                }
            </div>

            <Footer />
      </div>
    );
  }

}
