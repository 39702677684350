import React from "react";

export default class PayInformation extends React.Component {

  render() {
     let campPrice = this.props.price;
     let bankAccount = this.props.bankAccount;

    return (

	     <div>
		        <p>
              Děkujeme za vyplnění přihlášky, budeme se těšit. Závazně s Vámi počítáme.
            </p>
            <p>
              Platbu {campPrice} prosím uhraďte na účet <b>{bankAccount}</b>
            </p>
            <p>
              S dalšími bližšími informacemi Vás telefonicky či mailem kontaktujeme.
            </p>
            <br/>
            <div>
              S pozdravem
            </div>
            <div className="indentTop10">
              Rodina Just4keepers CZ
            </div>
	     </div>
    );
  }

}
