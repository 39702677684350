import React from "react";

import Header from '../../header';
import Footer from '../../footer';

import {getCampIntroduction, loadCampsDataAndRender, loadPhotoGalleryImages, getPhotosFromGallery} from './CampsUtil';
import PhotoGallery from '../../components/PhotoGallery';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import ApplicationFormSilvester from '../../components/forms/ApplicationFormSilvester';

const eventName = "Silvestrovské chytání";

export default class SilvesterDay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("silvester_camps", this);
      loadPhotoGalleryImages("silvester_camps", eventName, this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;

    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);
    }

    let loading = isLoading || isIntroLoading || isPhotoGalleryLoading

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (  
      <div>
            {hideHeaderAndFooter ? '' : <Header />}
		        <div className="view textArticle camps">

              {getCampIntroduction(campIntroduction, photoUrl)}

               <ApplicationFormSilvester />

              <p>
               <a href='Personal-data-agreement-J4K.pdf' download="Souhlas-se-zpracovanim-osobnich-udaju-J4K.pdf">Souhlas-se-zpracováním-osobních-údajů-J4K</a>
              </p>

              <PhotoGallery photos={getPhotosFromGallery(eventName, photoGallery)}/>

            </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>
    );
  }

}
