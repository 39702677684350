import React from "react";

//import MainArticleData from '../../components/data/MainArticleData';
import tryTrainingImage from '../../../images/all_panoramatic.jpg';

import Header from '../../../header';
import Footer from '../../../footer';


export default class TryTraining extends React.Component {

  render() {
    //const articleData = new MainArticleData();

    return (
      <div>
    		<Header />
		        <div className="textArticle try-training">

            <div
              key="try_training"
              className="try-training-image"
              style={{ background: `url('${tryTrainingImage}') no-repeat center center` }}
            />

            <div className="try-training-content">
              <h2 className="headline">
                Chtěl bych:
              </h2>
              <ul class="arrow-li">
                 <li>Pravidelný <b>skupinový</b> trénink.</li>
                 <li><b>Individuální</b> trénink.</li>
                 <li>Zažít <b>kemp.</b></li>
                 <li><b>Koupit rukavice</b> a vybavení.</li>
                 <li><b>Kondiční plán</b>.</li>
                 <li><b>Trenérskou</b> příležitost.</li>
             </ul>
            </div>


            </div>

        <Footer />
      </div>
    );
  }
}
