import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';

export default class PhotoGallery extends Component {

  render () {
    const photos = this.props.photos;

    return (
      <div>
        <Gallery images={photos} />
      </div>
    )
  }
}
