import React from 'react';
import firebase from '../../database/firestore';

// Forms
import {Formik} from 'formik';
import {Form, Button, Col, InputGroup} from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import "../../css/bootstrap.scss";
// Form validation
import * as yup from 'yup';

// Notification pop up
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import MainArticleData from '../data/MainArticleData';
import PayInformation from '../data/PayInformation';

import {createCampDropDownOptions} from '../../views/camps/CampsUtil';

const schema = yup.object({
  first_name: yup.string().required('Jméno je povinné'),
  last_name: yup.string().required('Příjmení je povinné'),
  birthday_year: yup.string().required('Rok narození je povinný'),
  phone: yup.string()
            .required('Telefoní číslo je povinné'),
  email: yup.string()
            .email('Nevalidní emailová adresa')
            .required('Email je povinný'),
  terms: yup.string().required('Musíte souhlasit se zpracováním osobních údajů pro úspěšné odesláním'),
});

const webClientUID = "56128530574-jlsm26805s3dqeug21gp38cbvjffs8rf.apps.googleusercontent.com";

class ApplicationFormSilvester extends React.Component {

  constructor() {
   super();
   this.multiselectRef = React.createRef();
   this.applicantForm = React.createRef();

   this.state = {
    campaignOptions: createCampDropDownOptions("silvester_camps")
   };
  }

  saveApplication(eventPlace, eventDate, values, collectionPath) {
    console.log(collectionPath);
    const db = firebase.firestore();
    db.settings({
      //timestampsInSnapshots: true // without this, we get a warning in the console, which tells us the timestamps stored in Firestore will be read back as timestamp objects, as opposed to system date objects.
    });
    db.collection(collectionPath)
      .doc(new Date().toString())
      .set({
        first_name: values.first_name,
        last_name: values.last_name,
        birthday_year: values.birthday_year,
        email: values.email,
        phone: values.phone,
        football_club: values.football_club,
        uid: webClientUID,

        personal_data_process_agreement: 'Yes'})
      .then(() => {
        console.log("Sucessfull insert into applications path");
       }).catch(error => {
        console.log(error);
    });

    // duplicate date for filtering
    let documentId = values.last_name + " " + values.first_name
                     + " (" + values.email + ")"
                     + " - " + eventPlace
                     + " : " + eventDate;
    db.collection("applications_overview")
      .doc(documentId)
      .set({
        first_name: values.first_name,
        last_name: values.last_name,
        birthday_year: values.birthday_year,
        email: values.email,
        phone: values.phone,
        football_club: values.football_club,
        event_place: eventPlace,
        event_date: eventDate,
        z_create_date: new Date().toString(),
        uid: webClientUID }
      ).then(() => {
        let notificationOptions = {
          type: toast.TYPE.SUCCESS,
          autoClose: false,
        };

        let bankAccount = new MainArticleData().getBankAccountNrForCamps();
        toast.success(<PayInformation bankAccount={bankAccount}/>, notificationOptions);
       }).catch(error => {
         let errorNotificationOptions = {
           autoClose: false,
           type: toast.TYPE.ERROR
         };
         toast.error("Odeslání přihlášky se nezdařilo, zkuste to prosím později.", errorNotificationOptions);

         console.log(error);
    });
  };

  addNewApplications = values => {
    this.multiselectRef.current.getSelectedItems().map(camp => (
      this.saveApplication(camp.id.split('_')[0], camp.id.split('_')[1], values,
        'applications/'
        + camp.id.split('_')[0]  // Camp place
        + '/' + camp.id.split('_')[1] // Camp date
        + '/' + values.last_name + " " + values.first_name  // Applicatant name
        + '/detail')
      )
    );

    this.resetValues();
  };

  resetValues() {
    //this.multiselectRef.current.resetSelectedValues();
    this.applicantForm.current.reset();
   }

  render() {
    return (
      <div id='applicationForm' className="use-bootstrap">
         <h4>Online přihláška</h4>
        <Formik
             validationSchema={schema}
             onSubmit={ values => this.addNewApplications(values) }
             initialValues={{
               football_club: ''
             }}
           >
             {({
               handleSubmit,
               handleChange,
               handleBlur,
               values,
               touched,
               isValid,
               errors
             }) => (
               <Form noValidate onSubmit={handleSubmit}
                                ref={this.applicantForm}>
                 <Form.Row>
                   <Form.Group as={Col} md="11" controlId="validationFormikCamp">
                     <Form.Label>Vyberte prosím údálost</Form.Label>
                      <Multiselect
                        options={this.state.campaignOptions} // Options to display in the dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder= "Klikněte sem pro výběr"
                        emptyRecordMsg="Žádná další údálost zatím není dostupná"
                        ref={this.multiselectRef}
                      />
                    </Form.Group>
                 </Form.Row>

                 <Form.Row>
                   <Form.Group as={Col} md="4" controlId="validationFormik01">
                     <Form.Label>Jméno</Form.Label>
                      <Form.Control
                         type="text"
                         placeholder="Jméno brankáře"
                         name="first_name"
                         value={values.first_name}
                         onChange={handleChange}
                         isInvalid={!!errors.first_name}
                         isValid={errors.first_name}
                      />

                      <Form.Control.Feedback type="invalid">
                         {errors.first_name}
                      </Form.Control.Feedback>
                   </Form.Group>
                   <Form.Group as={Col} md="4" controlId="validationFormik02">
                     <Form.Label>Příjmení</Form.Label>
                     <Form.Control
                       type="text"
                       placeholder="Příjmení brankáře"
                       name="last_name"
                       value={values.last_name}
                       onChange={handleChange}
                       isInvalid={!!errors.last_name}
                     />

                     <Form.Control.Feedback type="invalid">
                        {errors.last_name}
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="validationFormik03">
                    <Form.Label>Rok narození</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="např. 2011"
                      name="birthday_year"
                      value={values.birthday_year}
                      onChange={handleChange}
                      isInvalid={!!errors.birthday_year}
                      />
                    <Form.Control.Feedback type="invalid">
                      {errors.birthday_year}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik07">
                    <Form.Label>Fotbalový klub</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="např. AC Sparta Praha :-)"
                      name="football_club"
                      value={values.football_club}
                      onChange={handleChange}
                      isInvalid={!!errors.football_club}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.football_club}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="validationFormikEmail">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="email"
                        placeholder="váše emailová adresa"
                        aria-describedby="inputGroupPrepend"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationFormikPhone">
                      <Form.Label>Telefon zákonného zástupce</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="telefoní číslo"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isInvalid={!!errors.phone}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                 <Form.Group>
                   <Form.Check
                     required
                     name="terms"
                     label="Souhlasím se zpracováním osobních údajů"
                     onChange={handleChange}
                     isInvalid={!!errors.terms}
                     feedback={errors.terms}
                     id="validationFormik0"
                   />
                 </Form.Group>
                 <div className="termsLink">
                   <a style={{display: "table-cell"}} href="/#privacyPolicyAndSecurity" target="_blank">podmínky zde</a>
                 </div>
                </Form.Row>
                 <Button type="submit" >Odeslat přihlášku</Button>
               </Form>
             )}
           </Formik>

           <ToastContainer position="top-center" className="toast-container" />

      </div>
        );
      }
}
export default ApplicationFormSilvester;
