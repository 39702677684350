import React, { Component } from "react";

import Avatar from 'react-avatar';

import Header from '../header';
import '../css/styles.css';

//Footer
import Footer from '../footer';

// Contacts data
import MainArticleData from '../components/data/MainArticleData'


class Contacts extends Component {
  render() {
    const contactsData = new MainArticleData();
    const contacts = contactsData.getContacts();
    const emailContact = contactsData.getEmailContact();
    return (
	<div>
		<Header />

		<div class="view">
      <table className="contactsTable">
        <tbody>
          {contacts.map((person, index) => (
            <tr>
              <td><Avatar src={person.foto} size="70" round={true} /></td>
              <td className="contactName">{person.name}</td>
              <td className="contactPhone">{person.phone}</td>
            </tr>
          ))}
          <tr>
            <td><Avatar src={emailContact.icon} size="60" round={false} /></td>
            <td colSpan="2" className="contactName">{emailContact.email}</td>
          </tr>
          {contactsData.getBankAccounts().map((bank, index) => (
            <tr>
              <td><Avatar src={bank.icon} size="60" round={false} /></td>
              <td className="contactName">{bank.accountNr}</td>
              <td className="contactPhone">{bank.purpose}</td>
            </tr>
          ))}


        </tbody>
      </table>
    </div>

    <Footer />

	</div>
    );
  }
}

export default Contacts;
