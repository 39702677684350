import React from "react";

import '../css/styles.css';

export default class TrainingGloves extends React.Component {


  render() {
    const article = this.props.article;


    return (
		        <div class="view textArticle">
                <div class="headline">
                  <h2>
                    {article.title}
                    </h2>
                </div>

                <p >
                    <img src={article.sliderImage} alt="Brankářské rukavice"/>

                     S dotazy a objednávkami rukavic nás prosím kontaktujete na <b class="orange">just4keeperscz@gmail.com</b>!

                     <p>
                        <a href="https://j4ksports.cz/">Odkaz na e-shop</a>
                    </p>

                </p>
            </div>
    );
  }
}
