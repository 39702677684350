import React from "react";

import { HashRouter } from "react-router-dom";

// Nested menu
import MetisMenu from 'react-metismenu';
import '../css/react-metismenu-standart.css';

/* Mobile menu */
const menuItems =[
{
    icon: 'icon-class-name',
    label: 'Domů',
    to: '#/',
},
{
    icon: 'icon-class-name',
    label: 'Brankářský trénink',
    content: [
       {
           icon: 'icon-class-name',
           label: 'Základní informace',
           to: '#slider-report/0',
       },
        {
            icon: 'icon-class-name',
            label: 'Kraj Vysočina',
            content: [
                {
                    icon: 'icon-class-name',
                    label: 'Jihlava',
                    to: '#training-location/jihlava',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Humpolec',
                    to: '#training-location/humpolec',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Pelhřimov ',
                    to: '#training-location/pelhrimov',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Ždár nad Sázavou',
                    to: '#training-location/zdar-nad-sazavou',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Ždírec nad Doubravou',
                    to: '#training-location/zdirec-nad-doubravou',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Třešť',
                    to: '#training-location/trest',
                },

                {
                    icon: 'icon-class-name',
                    label: 'Velké Meziříčí',
                    to: '#training-location/velke-mezirici',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Náměšť nad Oslavou',
                    to: '#training-location/namest-nad-oslavou',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Luka nad Jihlavou',
                    to: '#training-location/luka-nad-jihlavou',
                },

            ],
        },
        {
            icon: 'icon-class-name',
            label: 'Jižní Morava',
            content: [
                {
                    icon: 'icon-class-name',
                    label: 'Brno',
                    to: '#training-location/brno',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Blansko',
                    to: '#training-location/blansko',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Bystré ',
                    to: '#training-location/bystre',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Hustopeče ',
                    to: '#training-location/hustopece',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Břeclav',
                    to: '#training-location/breclav',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Lanžhot  ',
                    to: '#training-location/lanzhot',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Tišnov  ',
                    to: '#training-location/tisnov',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Kunštát',
                    to: '#training-location/kunstat',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Knínice u Boskovic',
                    to: '#training-location/kninice-u-boskovic',
                },
            //    {
          //          icon: 'icon-class-name',
          //          label: 'Lednice',
          //          to: '#training-location/lednice',
          //      }
            ],
        },

      //  {
      //      icon: 'icon-class-name',
      //      label: 'Zlínský kraj',
      //      content: [
      //          {
      //              icon: 'icon-class-name',
      //              label: 'Uherský Brod',
      //              to: '#training-location/uhersky-brod',
      //          },
      //      ],
      //  },


    ],
},
{
    icon: 'icon-class-name',
    label: 'Kempy & akce',
    content: [
        {
        icon: 'icon-class-name',
        label: 'Ukončení prázdnin',
        to: '#brankarskekempy/ukonceni-prazdnin',
        },
        {
            icon: 'icon-class-name',
            label: 'Letní kempy',
            to: '#brankarskekempy/letni',
        },
        {
            icon: 'icon-class-name',
            label: 'Májové mini šílenství',
            to: '#brankarskekempy/majove-mini-silenstvi',
        },
        {
            icon: 'icon-class-name',
            label: 'Den Brankářů',
            to: '#brankarskekempy/den-brankaru',
        },
        {
            icon: 'icon-class-name',
            label: 'Silvestrovské chytání',
            to: '#brankarskekempy/silvester',
        },
        {
            icon: 'icon-class-name',
            label: 'Podzimní chytání',
            to: '#brankarskekempy/podzimni',
        },
        {
            icon: 'icon-class-name',
            label: 'Zimní kempy',
            to: '#brankarskekempy/zimni',
        }
    ]
},
{
    icon: 'icon-class-name',
    label: 'Eshop',
    to: 'https://j4ksports.cz/',
},
{
      icon: 'icon-class-name',
      label: 'O nás',
      to: '#about',
},
{
    icon: 'icon-class-name',
    label: 'Reference',
    to: '#references',
},
{
    icon: 'icon-class-name',
    label: 'Kontakt',
    to: '#contacts',
},
{
    icon: 'icon-class-name',
    label: 'Pro členy',
    to: '#private/docs',
},

];



export default ({ close }) => (
  <HashRouter>
	   <div className="navigation">
       <MetisMenu content={menuItems} activeLinkFromLocation />
	    </div>
  </HashRouter>
);
