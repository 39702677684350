import React from "react";

import AboutUs from './AboutUs';
import RayNewland from './RayNewland';

import Header from '../../../header';
import Footer from '../../../footer';


export default class About extends React.Component {

  render() {
    return (
      <div>
    		<Header />
		        <div className="view textArticle">

              <AboutUs />

              <RayNewland />

            </div>

            <Footer />
      </div>
    );
  }
}
