import firebase from 'firebase';

const config = {
   apiKey: "AIzaSyBCtdV8ao5-fvac9SBesCQMggpzdXMe4Ds",
   authDomain: "just4keepers-8485d.firebaseapp.com",
   databaseURL: "https://just4keepers-8485d.firebaseio.com",
   projectId: "just4keepers-8485d",
   storageBucket: "just4keepers-8485d.appspot.com",
   messagingSenderId: "56128530574"
};

firebase.initializeApp(config);

export default firebase;
