import React from "react";

import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

import { Redirect } from 'react-router';
import YouTube from 'react-youtube';

import '../css/styles.css';

export default class TrainingOffers extends React.Component {


  handleEvent = event => {
    return <Redirect to="/" push={true} />
    //this.context.router.transitionTo('/slider-report/1');
       //this.props.history.push('/brankarskekempy/letni')
    // browserHistory.push("/slider-report/1");
  };

  render() {
    const opts = {
      height: '300',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };

    const article = this.props.article;
    return (
		        <div class="view textArticle">
              <div class="headline">
                <h2>
                  {article.title}
                </h2>
              </div>

                  <p>
                    <img src={article.sliderImage} alt="Trenérská příležitost"/>

                     {article.description}

                     <ul class="triangle-li">
                        <li>Just4keepers spolupracuje s mládežnickými mužstvy již mnoho let.</li>
                        <li>Jsme největší nezávislou brankářskou školou na světě.</li>
                        <li>Působíme již ve více než 30 zemích světa.</li>
                        <li>V České republice jsme s Vámi od roku 2016, kde prozatím převážně trénujeme v lokalitách Kraje Vysočina a Jihomoravského kraje.  Více o lokalitách se dozvíte zde.</li>
                        <li>Nepracujeme jako zástupce žádného profesionálního týmu.</li>
                        <li>Jsme tu pro všechny brankáře, kteří by si rádi užili specializovaný trénink, naučili se tak správnou techniku chytání a získali vetší sebevědomí do zápasů.</li>
                        <li>Spolupracujeme s mezinárodními trenéry z různých zemí, dohromady tvoříme jedinečný tým a společně s vámi jsme jedna velká J4K rodina. :))</li>
                        <li>Vlastní tréninková koncepce. </li>
                    </ul>
                    <p>
                      <br/>
                     <a href='Prihlaska-clena-spolku-J4K.pdf' download="Prihlaska-clena-spolku-J4K.pdf">Přihláška člena spolku</a>
                    </p>
                    <p>
                      <br/>
                     <a href='GDPR-J4K.pdf' download="GDPR-J4K.pdf">GDPR</a>
                    </p>
                    <div className="responsive left">
                        <div className={"column-1"}>
                            <h4>
                            Trénink na maximum
                            </h4>
                            <p>
                              Dáváme do toho všechno, co máme. Jinak to ani nejde!
                            </p>
                            <ul class="triangle-li">
                               <li>Zkušenosti z profesionálního fotbalu.</li>
                               <li>Vzdělání z licencí.</li>
                               <li>Individuální přístup.</li>
                               <li>Vlastní tréninková koncepce.</li>
                               <li>Odbourávání chyb a vhodná motivace.</li>
                               <li>Snaha jít příkladem.</li>
                               <li>Prohlubování lásky ke sportu.</li>
                               <li>RADOST A ZÁBAVA.</li>
                           </ul>
                        </div>
                        <div className={"column-2"}>
                          <YouTube
                            videoId="6LTtCQoHC44"
                            opts={opts}
                            onReady={this._onReady}
                            />
                        </div>
                        <div className={"column-3"}>
                          <button>
                            <a href= '#/training-videos'>Tréninková videa</a>
                          </button>
                        </div>
                   </div>
                    <div class="left">
                    <PricingTable highlightColor='#e67817' >
                        <PricingSlot  onClick={this.submit} buttonText='tel. 731 575 400' title='Skupinový trénink zdarma' priceText='0 Kč / 1. hodina'>
                            <PricingDetail> <b>Skupinový trénink</b></PricingDetail>
                            <PricingDetail> <b>První ukázkový trénink zdarma!</b></PricingDetail>
                            <PricingDetail strikethrough> <b>Trénink brankáři přímo na míru.</b></PricingDetail>
                        </PricingSlot>
                        <PricingSlot highlighted onClick={this.submit} buttonText='tel. 731 575 400' title='Skupinový trénink' priceText='800 Kč / měsíc'>
                            <PricingDetail> <b>Skupinový trénink</b></PricingDetail>
                            <PricingDetail> <b>1h tréninku</b> v určité lokalitě</PricingDetail>
                            <PricingDetail strikethrough> <b>Trénink brankáři přímo na míru.</b></PricingDetail>
                            <PricingDetail> <i> Měsíční platba se nekrátí při vynechání tréninků - při nemoci či zranění platí následovně* </i></PricingDetail>
                        </PricingSlot>
                        <PricingSlot  onClick={this.submit} buttonText='tel. 731 575 400' title='Individuální trénink' priceText='od 800 Kč / trénink'>
                            <PricingDetail> <b>Individuální trénink </b></PricingDetail>
                            <PricingDetail> 1h <b>specializovaného</b> tréninku</PricingDetail>
                            <PricingDetail> <b>Skupinová sleva</b> 2 brankáři 1300 Kč / trénink</PricingDetail>
                            <PricingDetail> <b>Skupinová sleva</b> 3 brankáři 1800 Kč / trénink</PricingDetail>
                            <PricingDetail> <b>Trénink brankáři přímo na míru.</b></PricingDetail>
                        </PricingSlot>
                        <PricingSlot  onClick={this.handleEvent} buttonText='dozvědet se více' title='Brankářské kempy' priceText='od 4500 Kč'>
                            <PricingDetail> <b>letní, zimní a „Vip“</b> kepmy</PricingDetail>
                        </PricingSlot>
                    </PricingTable>

                    <p>
                    <i>
                     *Při registraci počítáme s pravidelným docházením na tréninky a rezervujeme místo přímo pro vás, které také držíme při případném zranění či nemoci před dalšími zájemci. Pokud nastane tato možnost, v případě včasné omluvy z tréninku platí platba následovně - 1 trénink - 300,-, 2 tréninky - 500,-, 3 - 5 tréninků - 800,-. V případě dlouhodobého zranění prosím dejte vědět, vyřešíme individuálně :). Moc prosíme o včasnou omluvenku z tréninků a pravidelnou účast - skrz plánování, dojíždění trenérů a velký zájem o brankářské tréninky můžeme pak fungovat tak, aby to mělo největší smysl a efekt.
                     </i>
                    </p>
                     </div>


                  </p>

            </div>
    );
  }

}
