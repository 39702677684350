import React from "react";

import { NavLink } from "react-router-dom";

// Nested menu
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu';

/* Desktop menu */
const locations =
{
    icon: 'icon-class-name',
    label: 'Brankářský trénink',
    content: [
        {
            icon: 'icon-class-name',
            label: 'Kraj Vysočina - INVISIBLE',
            content: [
                {
                    icon: 'icon-class-name',
                    label: 'Rantířov',
                    to: '#training-location/rantirov',
                },
              //  {
              //      icon: 'icon-class-name',
            //        label: 'Chotěboř',
            //        to: '#training-location/chotebor',
            //    },
                {
                    icon: 'icon-class-name',
                    label: 'Humpolec',
                    to: '#training-location/humpolec',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Pelhřimov ',
                    to: '#training-location/pelhrimov',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Ždár nad Sázavou',
                    to: '#training-location/zdar-nad-sazavou',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Ždírec nad Doubravou',
                    to: '#training-location/zdirec-nad-doubravou',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Třešť',
                    to: '#training-location/trest',
                },
            //    {
            //        icon: 'icon-class-name',
          //          label: 'Třebíč',
          //          to: '#training-location/trebic',
          //      },
                {
                    icon: 'icon-class-name',
                    label: 'Velké Meziříčí',
                    to: '#training-location/velke-mezirici',
                },
          //      {
          //          icon: 'icon-class-name',
        //            label: 'Velká Bíteš',
        //            to: '#training-location/velka-bites',
        //        },
        {
            icon: 'icon-class-name',
            label: 'Náměšť nad Oslavou',
            to: '#training-location/namest-nad-oslavou',
        },
        {
            icon: 'icon-class-name',
            label: 'Luka nad Jihlavou',
            to: '#training-location/luka-nad-jihlavou',
        },
            ],
        },
        {
            icon: 'icon-class-name',
            label: 'Jižní Morava - INVISIBLE',
            content: [
                {
                    icon: 'icon-class-name',
                    label: 'Brno',
                    to: '#training-location/brno',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Blansko',
                    to: '#training-location/blansko',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Bystré ',
                    to: '#training-location/bystre',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Hustopeče ',
                    to: '#training-location/hustopece',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Břeclav',
                    to: '#training-location/breclav',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Lanžhot  ',
                    to: '#training-location/lanzhot',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Tišnov  ',
                    to: '#training-location/tisnov',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Kunštát',
                    to: '#training-location/kunstat',
                },
                {
                    icon: 'icon-class-name',
                    label: 'Knínice u Boskovic',
                    to: '#training-location/kninice-u-boskovic',
                },
          //      {
          //          icon: 'icon-class-name',
          //          label: 'Lednice',
          //          to: '#training-location/lednice',
          //      }
            ],
        },

      /*  {
            icon: 'icon-class-name',
            label: 'Zlínský kraj - INVISIBLE',
            content: [
                {
                    icon: 'icon-class-name',
                    label: 'Uherský Brod',
                    to: '#training-location/uhersky-brod',
                }
            ],
        }, */

    ],
}

const camps=
{
    icon: 'icon-class-name',
    label: 'Kempy & akce',
    content: [
        {
          icon: 'icon-class-name',
          label: 'Ukončení prázdnin',
          to: '#brankarskekempy/ukonceni-prazdnin',
        },
        {
            icon: 'icon-class-name',
            label: 'Letní kempy',
            to: '#brankarskekempy/letni',
        },
        {
            icon: 'icon-class-name',
            label: 'Májové mini šílenství',
            to: '#brankarskekempy/majove-mini-silenstvi',
        },
        {
            icon: 'icon-class-name',
            label: 'Den Brankářů',
            to: '#brankarskekempy/den-brankaru',
        },
        {
            icon: 'icon-class-name',
            label: 'Silvestrovské chytání',
            to: '#brankarskekempy/silvester',
        },
        {
            icon: 'icon-class-name',
            label: 'Podzimní chytání',
            to: '#brankarskekempy/podzimni',
        },
        {
            icon: 'icon-class-name',
            label: 'Zimní kempy',
            to: '#brankarskekempy/zimni',
        }
    ]
}

const menu=[
{
      icon: 'icon-class-name',
      label: 'O nás',
      to: '/about',
},
{
    icon: 'icon-class-name',
    label: 'Reference',
    to: '/references',
},
{
    icon: 'icon-class-name',
    label: 'Kontakt',
    to: '/contacts',
},
{
    icon: 'icon-class-name',
    label: 'Pro členy',
    to: '/private/docs',
},

];

/* Desktop menu */
export default class MainMenu extends React.Component {
  state = {
            isLocationMenuOpen: false,
            isCampMenuOpen: false
           };

   toggleLocation = () => {
     this.setState({ isLocationMenuOpen: !this.state.isLocationMenuOpen });
   }

   closeLocation = () => {
     this.setState({ isLocationMenuOpen: false });
   };

   toggleCamp = () => {
     this.setState({ isCampMenuOpen: !this.state.isCampMenuOpen });
   }

   closeCamp = () => {
     this.setState({ isCampMenuOpen: false });
   };

   render() {
     const locationMenuOptions = {
       isOpen: this.state.isLocationMenuOpen,
       close: this.closeLocation,
       toggle: <a onClick={this.toggleLocation}>{locations.label}</a>,
       align: 'left',
     };

     const traingingProps = {
       toggle: <a href='#/slider-report/0'>Základní informace</a>,
       animate: true,
       openOnMouseover: false,
     };

     const vysocinaProps = {
       toggle: <a onClick={this.toggleLocation}>Kraj Vysočina</a>,
       animate: true,
       openOnMouseover: false,
     };

     const jihoMoravskyKrajProps = {
       toggle: <a onClick={this.toggleLocation}>Jihomoravský kraj</a>,
       animate: true,
       openOnMouseover: false,
     };

     const campsMenuOptions = {
       isOpen: this.state.isCampMenuOpen,
       close: this.closeCamp,
       toggle: <a onClick={this.toggleCamp}>{camps.label}</a>,
       align: 'left',
     };

     const campProps = {
       toggle: <a onClick={this.toggleCamps}>Kempy</a>,
       animate: true,
       openOnMouseover: false,
     };

     return (
       <div className="main-navigation header__column">
         <ul>

         <DropdownMenu {...locationMenuOptions}>
          <NestedDropdownMenu {...traingingProps}>
           {
           }
            </NestedDropdownMenu>
           <NestedDropdownMenu {...vysocinaProps}>
             {
             locations.content[0].content.map(location => (
               <li key={location}><a href={location.to}>{location.label}</a></li>
             ))
              }
           </NestedDropdownMenu>
           <NestedDropdownMenu {...jihoMoravskyKrajProps}>
             {
             locations.content[1].content.map(location => (
               <li key={location}><a href={location.to}>{location.label}</a></li>
             ))
              }
           </NestedDropdownMenu>

         </DropdownMenu>

        <li key='/eshop'>
          <NavLink to='/eshop'>Eshop</NavLink>
        </li>

         <DropdownMenu {...campsMenuOptions}>
             {
             camps.content.map(camp => (
               <li key={camp}><a href={camp.to}>{camp.label}</a></li>
             ))
              }
         </DropdownMenu>

         {menu.map(menuItem => (
             <li key={menuItem.to}>
               <NavLink to={menuItem.to}>{menuItem.label}</NavLink>
             </li>
         ))
       }

       </ul>

     </div>
     );
   }
 }
