import React from "react";
import Avatar from 'react-avatar';

export default class ProfileDetailComponent extends React.Component {

  render(activeTrainer) {
    const trainer = activeTrainer !== undefined ? activeTrainer : this.props.trainer;
    const visibility = this.props.visibility;
    const trainerIndex = this.props.trainerIndex;

    if(trainer === undefined) {
      return <div/>
    }

    return (

          <div className={visibility} id= {"trainer_" + trainerIndex}>
            <span>
              <table>
                <tbody>
                    <tr>
                      <td><Avatar src={trainer.foto} size="70" round={true} /></td>
                      <td><h1 className="profileHeadlineName">{trainer.degree} {trainer.name}</h1></td>
                    </tr>
                </tbody>
              </table>

            </span>

            <div>
                <span>
                  { trainer.youngthCareer.map((career, index) => (
                    <div key={"youngthTrainingCarier" + index}>
                      <h3>{index === 0 ? 'Mládežnická kariéra:' : ''}</h3>
                      {career}
                    </div>
                  ))
                  }
                </span>
            </div>

            <div>
                <h3>Hráčská kariéra:</h3>
                <span>
                   { trainer.playerCareer.map((career, index) => (
                      <div key={"playerCarier" + index}>{career}</div>
                      ))
                     }
                 </span>
            </div>

            <div>
                <h3>Trenérská kariéra:</h3>
                <span>
                   { trainer.trainerCareer.map((career, index) => (
                      <div key={"trainerCarier" + index}>{career}</div>
                      ))
                     }
                 </span>
            </div>

            <div>
                <h3>Vzdělání:</h3>
                <span>
                   { trainer.education.map((education, index) => (
                      <div key={"trainerEducation" + index}>{education}</div>
                      ))
                   }

                   <div>{trainer.thesis}</div>

                   { trainer.stages.map((stage, index) => (
                          <div key={"trainerStage" + index}>{stage}</div>
                          ))
                   }

                    <div>{trainer.licence}</div>
                 </span>
            </div>
          </div>
    );
  }

}
