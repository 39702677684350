import React from "react";

import Header from '../../header';
import Footer from '../../footer';
import NewsForm from '../../components/forms/admin/NewsForm';

export default class AddNewsPage extends React.Component {

  render() {
    return (
      <div>
           <Header />
           <div className="view textArticle camps">
              <h1>Přidání nové zprávy</h1>

              <NewsForm />
           </div>

            <Footer />
      </div>
    )
  }

}
