import React from "react";

import LoadingSpinner from '../../components/spinner/LoadingSpinner';

import Header from '../../header';
import Footer from '../../footer';
import TextReport from '../TextReport';

import {loadNewsAndRender, getWelcomePhoto, getPhotosFromGallery} from './NewsUtil'

export default class News extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newsData: [],
      photoGallery: [],
      welcomePhotos: [],
      isLoading: true
    };
  }

  componentDidMount(){
      loadNewsAndRender(this);
  }

  render() {
    const {newsData, isLoading, welcomePhotos, photoGallery} = this.state;

    let isWelcomePhotoLoading = true;
    let isGalleriesLoading = true;
    let numberOfGalleriesToLoad = 0;
    // dynamic loading of news from database
    if(!isLoading) {      
      newsData.filter(data => data.hasGallery).map(persistedNews => (
        numberOfGalleriesToLoad = numberOfGalleriesToLoad + 1
      ));

      isWelcomePhotoLoading = newsData.length < welcomePhotos.length;
      isGalleriesLoading = numberOfGalleriesToLoad < photoGallery.length;
    }

    let loading = isLoading || isWelcomePhotoLoading || isGalleriesLoading;
    console.log("isWelcomePhotoLoading= " + isWelcomePhotoLoading + " isLoading= " + isLoading + ". isGalleriesLoading= " + isGalleriesLoading);

    return loading ? (
            <LoadingSpinner loadingState={loading} />
        ) : (
      <div>
            <Header />

		        <div class="news">
              	{newsData.map((article, index) => (
                    <div >
                      <TextReport article={article} photo={getWelcomePhoto(article.title, welcomePhotos)} photoGallery={getPhotosFromGallery(article.title, photoGallery)} />
                    </div>
                ))
                }
            </div>

            <Footer />
      </div>
    );
  }
}
