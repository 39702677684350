import React from "react";

import Header from '../../header';
import Footer from '../../footer';

import Photo from '../../images/ukonceni-prazdin/Konec_prazdnin.jpg';


export default class UkonceniPrazdnin extends React.Component {

  render() {

    return (<div>
           <Header />
           <div className="view textArticle camps">


           <div className="headline">
             <h1>
              Ukončení prázdnin
             </h1>
           </div>

             <div className="indent">
             Čau šílenci 🤩,
             <br/>
             <br/>

tradičně dáme sbohem prázdninám jak se patří 🧤🧡.
<br/>
<br/>
Nasadíme ruce a s radostí sebou plácneme na zem 👌😄⚽.
<br/>
Lepší ukončení a vylazení formy snad ani nejde, tak doraž ‼️😍.
<br/>
<br/>
Těšíme se na tebe 🤝.

<br/>
<br/>
 <a href='https://just4keepers.dtap.cz/register/?link=15'>Přihlášky zde</a>

             </div>

             <div className="responsive left">
              <div className={"column-1"}>
               <img src={Photo} alt="Ukončení prázdnin"/>
               </div>
             </div>



            </div>

            <Footer />
      </div>
    );

  }
}
