import React from "react";

import Header from '../header';
import Footer from '../footer';

// Views
import TextReport from './TextReport';
import GoalKeeperTraining from './GoalKeeperTraining';
import SummerCamps from './camps/SummerCamps';
import TrainingOpportunity from './TrainingOpportunity';
import TrainingGloves from './TrainingGloves';

// Articles
import MainArticleData from '../components/data/MainArticleData'


export default class ArticleDetail extends React.Component {

  render() {
    const articleData = new MainArticleData();
    const articles = articleData.getArticleDetails();
    const article = articles[this.props.match.params.id];
    let detailPage = <TextReport article={article} />;
    if(article.trainingCamps) {
      detailPage = <SummerCamps hideHeaderAndFooter={true} />;
    } else if (article.trainingOffers) {
      detailPage = <GoalKeeperTraining article={article} />;
    } else if(article.trainingOpportunity) {
      detailPage = <TrainingOpportunity article={article} />;
    } else if(article.trainingGloves) {
      detailPage = <TrainingGloves article={article} />;
    }

    return (
	     <div>
		        <Header />
		          {detailPage}
            <Footer />
	     </div>
    );
  }

}
